import { useCallback, useEffect } from 'react';

import { useAppDispatch } from 'store';
import { setIsOnline } from 'store/features/user';

import { useEventListener } from '../useEventListener';

export const useInternetConnectionWatcher = () => {
  const dispatch = useAppDispatch();

  const testConnection = useCallback(
    async (isOnline: boolean) => {
      const headers = new Headers();
      headers.append('cache-control', 'no-cache');
      headers.append('pragma', 'no-cache');

      try {
        // while you can assume that the browser is offline when it returns a false value,
        // you cannot assume that a true value necessarily means that the browser can access the internet
        // source: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
        if (!isOnline) throw new Error();

        // additional check to make sure we are online
        await fetch(window.location.origin, { method: 'HEAD', headers });
        dispatch(setIsOnline(true));
      } catch (error) {
        dispatch(setIsOnline(false));
      }
    },
    [dispatch]
  );

  useEventListener('online', () => testConnection(true));
  useEventListener('offline', () => testConnection(false));

  useEffect(() => {
    testConnection(window.navigator.onLine);
  }, [testConnection]);
};

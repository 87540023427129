import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store';
import { selectIsOnline } from 'store/features/user';

import { Banner } from '../Banner';

export const NoInternetConnectionBanner: FC = () => {
  const { t } = useTranslation('common');
  const isOnline = useAppSelector(selectIsOnline);

  if (isOnline) return null;

  return (
    <Banner severity="warning" fixed>
      {t('noInternetConnection')}
    </Banner>
  );
};

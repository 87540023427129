import React, { FC, useEffect, useRef } from 'react';

import { UploadDropzone, type UploadDropzoneProps } from '../UploadDropzone';
import { UploadPreview, type UploadPreviewProps } from '../UploadPreview';
import { VideoAvatar, VideoAvatarContainer } from './VideoDropzoneWithPreview.styled';

type VideoDropzoneWithPreviewProps = UploadDropzoneProps &
  Pick<UploadPreviewProps, 'progress' | 'status' | 'onRemoveClick' | 'onCancelClick' | 'onTryAgainClick'> & {
    video?: {
      name: string;
      url: string;
      size?: number;
    };
  };

export const VideoDropzoneWithPreview: FC<VideoDropzoneWithPreviewProps> = ({
  progress,
  status,
  video,
  onCancelClick,
  onFileSelect,
  onRemoveClick,
  onTryAgainClick,
  disabled,
  ...videoDropzoneProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [video?.url]);

  if (video) {
    return (
      <UploadPreview
        name={video.name}
        progress={progress}
        size={video.size}
        status={status}
        onCancelClick={onCancelClick}
        onRemoveClick={onRemoveClick}
        onTryAgainClick={onTryAgainClick}
        disabled={disabled}
      >
        <VideoAvatarContainer flexDirection="row" justifyContent="center" alignItems="center">
          <VideoAvatar ref={videoRef}>
            <source src={`${video.url}?${new Date().getTime()}`} />
          </VideoAvatar>
        </VideoAvatarContainer>
      </UploadPreview>
    );
  }

  return <UploadDropzone type="video" onFileSelect={onFileSelect} disabled={disabled} {...videoDropzoneProps} />;
};

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { agoraActions } from './agora.actions';
import {
  audioTrackCreationCompletedEffect,
  audioTrackTogglingFailedEffect,
  cameraDeviceChangeFailedEffect,
  microphoneDeviceChangeFailedEffect,
  videoTrackCreationCompletedEffect,
  videoTrackTogglingFailedEffect,
} from './agora.effects';

export const agoraListener = createListenerMiddleware<RootState>();

// Audio related

agoraListener.startListening({
  actionCreator: agoraActions.audioTrack.creation.completed,
  effect: audioTrackCreationCompletedEffect,
});

agoraListener.startListening({
  actionCreator: agoraActions.audioTrack.toggling.failed,
  effect: audioTrackTogglingFailedEffect,
});

agoraListener.startListening({
  actionCreator: agoraActions.audioTrack.deviceChange.failed,
  effect: microphoneDeviceChangeFailedEffect,
});

// Video related

agoraListener.startListening({
  actionCreator: agoraActions.videoTrack.creation.completed,
  effect: videoTrackCreationCompletedEffect,
});

agoraListener.startListening({
  actionCreator: agoraActions.videoTrack.toggling.failed,
  effect: videoTrackTogglingFailedEffect,
});

agoraListener.startListening({
  actionCreator: agoraActions.videoTrack.deviceChange.failed,
  effect: cameraDeviceChangeFailedEffect,
});

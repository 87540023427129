import React, { forwardRef } from 'react';

import { styled } from '@mui/material';
import { DragIndicatorIcon } from 'icons';

export const DragIndicator = styled(
  forwardRef<HTMLDivElement>((props, ref) => (
    <div {...props} ref={ref}>
      <DragIndicatorIcon />
    </div>
  ))
)(({ theme: { palette } }) => ({
  color: palette.grey[400],
  cursor: 'grab',
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
}));

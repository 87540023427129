import { RefObject, useCallback, useEffect } from 'react';

export const useAutoScrollTop = (trigger: unknown, container?: RefObject<HTMLElement>) => {
  const root = document.getElementById('root') ?? document.body;

  const scrollTop = useCallback(() => {
    if (container?.current && 'scrollTo' in container.current) {
      container.current.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if ('scrollTo' in root) {
      root?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [container, root]);

  useEffect(() => {
    scrollTop();
  }, [trigger, scrollTop]);
};

import { logger } from 'common/services';
import { assert } from 'common/utils';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect } from 'store';
import { selectCurrentSurvey, surveyApi } from 'store/apis/survey';
import { addAppConfirmationDialog } from 'store/features/alerts';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import { DEFAULT_SURVEY_NAME } from '../constants';
import { publishSurvey, surveysWizardActions, updateSurvey } from './surveyWizard.actions';
import { selectActiveStep, selectIsEditMode } from './surveyWizard.selectors';

export const exitOrCanceledEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
};

export const untitledSurveyDetectedEffect: Effect = (_, { dispatch }) => {
  dispatch(
    addAppConfirmationDialog({
      title: 'Survey title is empty',
      description: 'The Survey title cannot be empty. Please enter a title to proceed.',
      severity: 'danger',
      confirmLabel: 'Understood',
      showWarningIcon: false,
      centered: true,
    })
  );
};

export const startCreateSurveyEffect: Effect = ({ payload: { name } }, { dispatch }) => {
  dispatch(surveysWizardActions.progressTracker.stepClicked(SetupSurveyProgressTrackerStep.Questions));
  dispatch(push(AppRoutes.SurveyWizardCreate, { name }));
};

export const publishSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
};

export const updateSucceededEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.Surveys));
  dispatch(
    addAppConfirmationDialog({
      title: 'Survey successfully updated',
      description: 'Your Survey was successfully updated and shared with your participants.',
      confirmLabel: 'Got it',
      showWarningIcon: false,
      centered: true,
    })
  );
};

export const deleteSurveyConfirmedEffect: Effect = async (_, { dispatch, getState }) => {
  const state = getState();
  const survey = selectCurrentSurvey(state);
  assert(survey);

  const { id } = survey;

  try {
    await dispatch(surveyApi.endpoints.deleteSurvey.initiate({ id })).unwrap();
    dispatch(push(AppRoutes.Surveys));
  } catch (error) {
    logger.error(error);
  }
};

export const nextButtonClickedEffect: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const currentStep = selectActiveStep(state);
  // @ts-expect-error - TS doesn't know that the enum is iterable
  const nextStep = SetupSurveyProgressTrackerStep[SetupSurveyProgressTrackerStep[currentStep + 1]];

  dispatch(surveysWizardActions.progressTracker.stepCompleted(currentStep));
  dispatch(surveysWizardActions.progressTracker.stepClicked(nextStep));
};

export const backButtonClickedEffect: Effect = (_, { dispatch, getState }) => {
  const state = getState();

  const currentStep = selectActiveStep(state);
  // @ts-expect-error - TS doesn't know that the enum is iterable
  const nextStep = SetupSurveyProgressTrackerStep[SetupSurveyProgressTrackerStep[currentStep - 1]];

  dispatch(surveysWizardActions.progressTracker.stepCompleted(currentStep));
  dispatch(surveysWizardActions.progressTracker.stepClicked(nextStep));
};

export const formSubmittedEffect: Effect<ReturnType<typeof surveysWizardActions.form.submitted>> = (
  { payload },
  { dispatch, getState }
) => {
  if (!payload.name || payload.name === DEFAULT_SURVEY_NAME) {
    dispatch(surveysWizardActions.untitledSurveyDetected());
    return;
  }

  const state = getState();
  const isEditMode = selectIsEditMode(state);

  isEditMode ? dispatch(updateSurvey(payload)) : dispatch(publishSurvey(payload));
};

import type { Components, Theme } from '@mui/material';

export const MuiCardActionArea: Components<Theme>['MuiCardActionArea'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      height: '100%',
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
      '&[data-active]': {
        backgroundColor: palette.extendedPrimary[25],
      },
    }),
  },
};

import type { Account, AccountApi } from 'domain/Account';

export const mapAccountFromApi = (account: AccountApi): Account => ({
  id: account.id,
  username: account.username,
  email: account.email,
  firstName: account.firstName,
  lastName: account.lastName,
  displayName: `${account.firstName} ${account.lastName}`,
  avatarLink: account.avatarLink,
  isOrganizationMember: account.isOrganizationMember,
});

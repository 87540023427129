import { Dispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { initMergedDataDispatch } from 'store/utils/throttledDispatch';

import { reactionReceived_WS } from './actions';
import { resetReactionsState, setReactions } from './reactionsSlice';
import { ReceivedReaction } from './types';

const REACTIONS_THROTTLE_TIMEOUT = 1000;

type ReactionHandlerType = ReturnType<typeof initMergedDataDispatch<string, { reaction: string }>>;
const reactionsHandlersMap = new Map<string, ReactionHandlerType>();

/**
 * Throttles reactions separately for each user and each reaction type
 * @param dispatch
 * @param receivedReaction
 */
const groupReactions = (dispatch: Dispatch, { reaction }: ReceivedReaction) => {
  // eslint-disable-next-line functional/no-let
  let reactionHandler = reactionsHandlersMap.get(reaction);
  if (!reactionHandler) {
    reactionHandler = initMergedDataDispatch<string, { reaction: string }>(
      (data, extras) => {
        dispatch(setReactions({ count: data.length, reaction: extras.reaction }));
      },
      { timeout: REACTIONS_THROTTLE_TIMEOUT }
    );
    reactionsHandlersMap.set(reaction, reactionHandler);
  }
  reactionHandler([reaction], { dispatch, reaction });
};

export const reactionsListener = createListenerMiddleware<RootState>();

reactionsListener.startListening({
  actionCreator: reactionReceived_WS,
  effect: ({ payload }, { dispatch }) => {
    groupReactions(dispatch, payload);
  },
});

reactionsListener.startListening({
  actionCreator: resetReactionsState,
  effect: () => {
    reactionsHandlersMap.clear();
  },
});

import React, { FC, ReactNode } from 'react';
import { useFormState } from 'react-hook-form';

import { Typography } from '@mui/material';
import classNames from 'classnames';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { get } from 'lodash';

import { useTextInputStyles } from './InputWrapper.styles';

export type InputWrapperProps = {
  children?: ReactNode;
  label?: string;
  hint?: string;
  name: string;
  required?: boolean | string;
  justifyCenter?: boolean;
  autoWidth?: boolean;
  className?: string;
  centered?: boolean;
  title?: string;
};

export const InputWrapper: FC<InputWrapperProps> = ({
  children,
  label = '',
  hint = '',
  name,
  required = false,
  justifyCenter = false,
  autoWidth,
  className,
  centered = true,
  title,
}) => {
  const classes = useTextInputStyles();
  const { errors } = useFormState({ name });
  const errorMessage = get(errors, name)?.message;

  return (
    <div
      className={classNames(
        classes.container,
        centered && classes.centered,
        { [classes.autoWidth]: !!autoWidth },
        className
      )}
      data-testid={`inputWrapper-${name}`}
    >
      {title && (
        <Typography
          fontSize="14px"
          fontWeight="bold"
          pb={3}
          className={classNames({
            [classes.justifyCenter]: justifyCenter,
          })}
        >
          {title}
        </Typography>
      )}
      {label && hint && (
        <Typography
          variant="h2"
          color="textPrimary"
          className={classNames({
            [classes.justifyCenter]: justifyCenter,
          })}
        >
          {hint ? label : null}
          {label && required ? '*' : ''}
        </Typography>
      )}
      {hint && (
        <Typography variant="subtitle1" className={classes.hint} gutterBottom>
          {hint}
        </Typography>
      )}
      {children}
      <FormFieldHint error>{errorMessage}</FormFieldHint>
    </div>
  );
};

import React, { FC, MouseEventHandler, ReactNode, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import { MoreVerticalIcon } from 'icons';

import { Menu, MenuProps } from '../../Menu';
import type { IconButtonVariant } from '../IconButton/types';
import { IconButtonWithTooltip, IconButtonWithTooltipProps } from '../IconButtonWithTooltip';

type Props = Omit<IconButtonWithTooltipProps, 'title' | 'ref'> &
  Pick<MenuProps, 'anchorOrigin' | 'transformOrigin' | 'items' | 'activeIndex'> & {
    'data-testid'?: string;
    menuSx?: SxProps<Theme> | undefined;
    variant?: IconButtonVariant;
    Icon?: ReactNode;
  } & {
    title?: string;
  };

export const ButtonMenu: FC<Props> = ({
  anchorOrigin,
  transformOrigin,
  menuSx,
  isLoading,
  size,
  variant = 'text',
  Icon = <MoreVerticalIcon />,
  color = variant === 'text' ? 'text' : undefined,
  title = 'More actions',
  TooltipProps,
  ...props
}) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <IconButtonWithTooltip
        onClick={handleClick}
        variant={variant}
        size={size}
        className={anchor ? 'active' : undefined}
        color={color}
        title={title}
        TooltipProps={{ placement: 'bottom-end', ...TooltipProps }}
        isLoading={isLoading}
        data-testid={props['data-testid']}
      >
        {Icon}
      </IconButtonWithTooltip>

      <Menu
        sx={menuSx}
        anchor={anchor}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...props}
      />
    </>
  );
};

import { Stack, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  isDragging: boolean;
};

export const IconContainer = styled(Stack, { shouldForwardProp: omitForwardedProps('isDragging') })<Props>(
  ({ isDragging, theme: { palette } }) => ({
    alignItems: 'center',
    backgroundColor: palette.grey[100],
    border: `6px solid ${palette.grey[50]}`,
    borderRadius: '50%',
    color: palette.grey[600],
    justifyContent: 'center',
    height: 40,
    width: 40,
    pointerEvents: 'none',

    ...(isDragging && {
      backgroundColor: palette.extendedPrimary[50],
      borderColor: palette.primary.light,
      color: palette.primary.main,
    }),
  })
);

type DropzoneContainerProps = Props & {
  disabled: boolean;
};

export const DropzoneContainer = styled(Stack, {
  shouldForwardProp: omitForwardedProps('isDragging', 'disabled'),
})<DropzoneContainerProps>(({ isDragging, disabled, theme: { palette, spacing } }) => ({
  alignItems: 'center',
  border: `1px dashed ${palette.primary.main}`,
  borderRadius: spacing(1),
  padding: spacing(2, 3),
  position: 'relative',
  textAlign: 'center',
  opacity: disabled ? 0.5 : 1,

  ...(isDragging && {
    backgroundColor: palette.extendedPrimary[25],
  }),
}));

export const TextContainer = styled(Stack)(({ theme: { spacing } }) => ({
  marginTop: spacing(1.5),
  pointerEvents: 'none',
}));

export const FileInput = styled('input')(({ disabled }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 0,
  cursor: 'pointer',
  ...(disabled && {
    cursor: 'default',
    pointerEvents: 'none',
  }),
}));

import React, { Fragment, MouseEventHandler, PropsWithChildren, useState } from 'react';

import { Stack, TableCell, TableRow } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { ChevronRightIcon } from 'icons';

import type { TableRowProps } from '../../types';

type Props<ItemType, ColumnIdType> = PropsWithChildren<TableRowProps<ItemType, ColumnIdType>>;

export const ExpandableTableRow = <ItemType, ColumnIdType extends string = string>({
  row,
  onRowClick,
  hover,
  columns,
  ExpandedComponent,
  ...props
}: Props<ItemType, ColumnIdType>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { isExpandable, disabled } = row;

  const handleClick: MouseEventHandler<HTMLTableRowElement> | undefined =
    (onRowClick || isExpandable) && !disabled
      ? () => {
          if (isExpandable) {
            setOpen(!open);
            return;
          }

          onRowClick?.(row);
        }
      : undefined;

  return (
    <Fragment>
      <TableRow hover={disabled ? false : hover} tabIndex={-1} onClick={handleClick} data-testid={props['data-testid']}>
        {ExpandedComponent && (
          <TableCell>
            <Stack justifyContent="center">
              {isExpandable && (
                <ChevronRightIcon
                  fontSize="medium"
                  sx={{
                    transform: open ? 'rotate(90deg)' : 'rotate(0)',
                    transition: 'transform 100ms',
                  }}
                />
              )}
            </Stack>
          </TableCell>
        )}
        {columns.map((column) => {
          const value = column.format(row);
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={column.style}
              width={column.width}
              title={typeof value === 'string' ? value : ''}
              padding={column.padding}
              data-testid={`cell-${column.id}`}
            >
              {value}
            </TableCell>
          );
        })}
      </TableRow>

      {ExpandedComponent && <AnimatePresence>{open && <ExpandedComponent row={row} />}</AnimatePresence>}
    </Fragment>
  );
};

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SocketState } from './types';

const initialState: SocketState = {
  connectedRooms: [],
  isConnected: false,
  isReconnecting: false,
  connectionErrorsCount: 0,
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    addConnectedRoom: (state, action: PayloadAction<string>) => {
      state.connectedRooms.push(action.payload);
    },
    removeConnectedRoom: (state, action: PayloadAction<string>) => {
      const index = state.connectedRooms.indexOf(action.payload);
      if (index > -1) {
        state.connectedRooms.splice(index, 1);
      }
    },
    websocketConnected: (state) => {
      state.isConnected = true;
    },
    websocketDisconnected: (state) => {
      state.isConnected = false;
    },
    websocketReconnecting: (state) => {
      state.isReconnecting = true;
    },
    websocketCleanupAfterReconnect: (state) => {
      state.isReconnecting = false;
    },
    websocketResetConnectionStats: (state) => {
      state.connectionErrorsCount = 0;
    },
    websocketConnectionError: (state) => {
      state.connectionErrorsCount++;
    },
  },
});

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { mapToMeetingGuestFromApi } from 'event/mappers';
import type { RootState } from 'store';
import { userApi } from 'store/apis/user';

import {
  addUserToAudience,
  broadcastGuestDemoted_WS,
  selectIsCurrentUserEventHost,
  selectIsUserEventHost,
} from '../event';
import { searchBroadcastParticipants_WS } from './actions';
import { resetParticipantSearch, setParticipantsSearchIsLoading } from './participantsSearchSlice';

export const participantsSearchListener = createListenerMiddleware<RootState>();

participantsSearchListener.startListening({
  actionCreator: searchBroadcastParticipants_WS.pending,
  effect: (_args, { dispatch }) => {
    dispatch(setParticipantsSearchIsLoading(true));
  },
});

participantsSearchListener.startListening({
  actionCreator: broadcastGuestDemoted_WS,
  effect: ({ payload }, { getState, dispatch }) => {
    const state = getState();
    const speaker = mapToMeetingGuestFromApi(payload);
    const isSpeakerEventHost = selectIsUserEventHost(state, speaker.id);

    if (state.event.isBigMeetingMode && isSpeakerEventHost) {
      dispatch(addUserToAudience(speaker));
    }

    const isUserHost = selectIsCurrentUserEventHost(state);
    if (isUserHost) return;

    const userAccountId = userApi.endpoints.getAccount.select()(state).data?.id;
    const isUser = userAccountId === speaker.id;
    if (isUser) {
      dispatch(resetParticipantSearch());
    }
  },
});

import { DateISOString } from 'domain/Common';

export enum EventStatus {
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Published = 'PUBLISHED',
  Started = 'STARTED',
}

export type EventOwnerBase = {
  id: number;
  fullName: string;
};

export type EventOwner = EventOwnerBase & {
  email: string;
};

export type EventTime = {
  id: number;
  startTime: DateISOString;
  durationInMinutes: number;
};

export enum AgoraProxyMode {
  NoProxy = 0,
  ForceUdp = 3,
  ForceTcp = 5,
}

export enum EventQuestionType {
  PreEvent = 'PRE_EVENT',
  Feedback = 'FEEDBACK',
  EndOfEvent = 'END_OF_EVENT',
  Icebreaker = 'ICEBREAKER',
}

export interface Topic {
  id: number;
  value: string;
}

import type { Components, Theme } from '@mui/material';

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      height: '100%',
      padding: spacing(2, 2, 3, 2),
    }),
  },
};

import { createSelector } from '@reduxjs/toolkit';
import { DEFAULT_DATE_FORMAT } from 'common/components/Inputs/DatePicker';
import { formatTimeGMT } from 'common/utils/time';
import { format } from 'date-fns';
import { mapEventQuestionFromApi } from 'event/mappers';
import { some } from 'lodash';
import { identity, prop } from 'lodash/fp';
import { selectIdFromCurrentRoute } from 'store/features/router';

import { surveyApi } from './surveyApi';
import { AsyncSurveyStates } from './types';

export const selectCurrentSurvey = createSelector(identity, selectIdFromCurrentRoute, (state, id) =>
  state && id ? surveyApi.endpoints.getSurvey.select({ id })(state)?.data : undefined
);

export const selectCurrentSurveyQuestions = createSelector(identity, selectIdFromCurrentRoute, (state, id) =>
  state && id ? surveyApi.endpoints.getSurveyQuestions.select({ id })(state)?.data : undefined
);

export const selectCurrentSurveyQuestionsWithOrder = createSelector(selectCurrentSurveyQuestions, (questions) =>
  questions?.map((question, index) => mapEventQuestionFromApi({ withOrder: true })(question, index + 1))
);

export const selectCurrentSurveyAnswers = createSelector(identity, selectIdFromCurrentRoute, (state, id) =>
  state && id ? surveyApi.endpoints.getSurveyAnswers.select({ id })(state)?.data : undefined
);

export const selectIsLoadingCurrentSurveyData = createSelector(identity, selectIdFromCurrentRoute, (state, id) => {
  if (!state || !id) return false;

  return some(
    [surveyApi.endpoints.getSurvey, surveyApi.endpoints.getSurveyQuestions, surveyApi.endpoints.getSurveyAnswers],
    (endpoint) => endpoint.select({ id })(state)?.isLoading
  );
});

export const selectSurveyId = selectIdFromCurrentRoute;
export const selectCurrentSurveyName = createSelector(selectCurrentSurvey, prop('name'));
export const selectCurrentSurveyState = createSelector(selectCurrentSurvey, prop('state'));
export const selectCurrentSurveyDueBy = createSelector(selectCurrentSurvey, prop('dueBy'));
export const selectCurrentSurveyCompletedAt = createSelector(
  selectCurrentSurveyDueBy,
  (dueBy) => dueBy && `${format(new Date(dueBy), DEFAULT_DATE_FORMAT)} at ${formatTimeGMT(dueBy)}`
);
export const selectCurrentSurveyIsCompleted = createSelector(
  selectCurrentSurveyState,
  (state) => state === AsyncSurveyStates.Completed
);
export const selectCurrentSurveyInvitedUsersCount = createSelector(selectCurrentSurvey, prop('invitedUsers'));
export const selectCurrentSurveyResponsesReceivedCount = createSelector(selectCurrentSurvey, prop('responsesReceived'));

export const selectIsCurrentQuestionsReadOnly = createSelector(
  selectCurrentSurveyQuestions,
  selectCurrentSurveyState,
  selectCurrentSurveyResponsesReceivedCount,
  (questions, state, responsesReceived) => {
    if (!questions) return false;

    return state !== AsyncSurveyStates.Unpublished || !!responsesReceived;
  }
);

import type { ImageFile } from 'common/components/_legacy/Form';
import { type UserPrivilege, UserPrivilegeName, type UserRole, UserRoleName } from 'domain/UserProfile';
import type { Attachment } from 'store/apis/attachments/types';

import { TagsCategory } from './Common';

export enum ProxyMode {
  Automatic = 'AUTOMATIC',
  NoProxy = 'NO_PROXY',
  TcpProxy = 'TCP_PROXY',
  UdpProxy = 'UDP_PROXY',
}

export interface CommunityGuest {
  email: string;
  communityRole: UserRoleName;
  privileges: UserPrivilegeName[];
}

export interface CommunitySettings {
  membersLimit: number;
  eventsPerMonthLimit: number;
  usersPerEventLimit: number;
  recurringNattersPerMonthLimit: number;
  usersPerRecurringNattersLimit: number;
  transcriptionEnabled: boolean;
  microsoftSSOEnabled: boolean;
  googleSSOEnabled: boolean;
  companySSOEnabled: boolean;
  proxyMode: ProxyMode;
}

export interface CommunityApiPayload {
  id: number;
  hash: string;
  name: string;
  logoImageLink: string | null;
  logoImageLinkSmall: string | null;
  /** @deprecated */
  brandingColor: string | null;
  tagsCategories: TagsCategory[];
  ownerId: number;
  communityMembersCount: number;
  communitySettings: CommunitySettings;
  defaultEventImage?: Attachment | null;
  slug?: string;
}

export interface Community {
  id: number;
  hash: string;
  name: string;
  logoImageLink: string | undefined;
  logoImageLinkSmall: string | undefined;
  /** @deprecated */
  brandingColor: string | undefined;
  tagsCategories: TagsCategory[];
  ownerId: number;
  communityMembersCount: number;
  communitySettings?: CommunitySettings;
  defaultEventImage?: Attachment | null;
  slug?: string;
}

export interface CommunityMetrics {
  communityMembersCount: number;
  pastEventsCount: number;
  responsesCaptured: number;
  completedP2PMonths?: number;
}

export interface Guest {
  accountId: number;
  id: number;
  name: string;
  email: string;
  displayName: string;
  avatarLink: string;
  role: string;
}

export interface CommunityMemberFormData {
  avatar: { data: string; path: string };
  socials: Partial<CommunityMemberSocials>;
  tagsCategories: TagsCategory[];
  role: string;
  communityRole: UserRoleName;
  privileges: UserPrivilegeName[];
}

export interface CommunityMemberApiPayload {
  socials?: Partial<CommunityMemberSocials>;
  communityRole: UserRoleName;
  tagsCategories?: TagsCategory[];
  hasCronofyCredentials: boolean;
  accountId: number;
  id: number;
  name: string | null;
  email: string | null;
  displayName: string | null;
  avatarLink: string | null;
  role: string | null;
  privileges?: UserPrivilege[];
  isOrganizationMember?: boolean;
}

export interface CommunityMember extends Guest {
  socials?: Partial<CommunityMemberSocials>;
  communityRole: UserRoleName;
  tagsCategories: TagsCategory[];
  hasCronofyCredentials: boolean;
  privileges: UserPrivilege[];
  isOrganizationMember: boolean;
}

export interface CommunityMemberSocials {
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  twitter?: string | null;
}

export interface CommunityTag {
  id: number;
  name: string;
  category?: string;
}

/** @deprecated  TODO: Infer type from community form schema */
export interface CommunityFormValues {
  id?: number;
  hash?: string;
  name: string;
  color: { hex: string };
  brandingColor?: string;
  tagsCategories: (TagsCategory & { newTagName: string; expanded: boolean })[];
  logo: ImageFile & { isLoading?: boolean };
  email: string;
  guests: CommunityGuest[];
  communitySettings: CommunitySettings;
}

export interface CreateCommunityRequestBody {
  /** @deprecated */
  brandingColor?: string;
  name: string;
  tagsCategories?: TagsCategory[];
  communitySettings?: CommunitySettings;
}

export interface UpdateCommunityRequestBody extends CreateCommunityRequestBody {
  logoImageLink?: string | null;
  defaultEventImageAttachmentId?: string | null;
}

/** @deprecated Please use **UpdateCommunityRequest** from `src/store/apis/community/types.ts` */
export interface UpdateCommunityData {
  id: number;
  logo: ImageFile | ImageData;
  logoImageLink?: string;
  brandingColor?: string;
  name: string;
  tagsCategories: TagsCategory[];
  guests: CommunityGuest[];
  defaultEventImageAttachmentId?: string | null;
}

export interface CommunityAdminToggleRequest {
  accountId: number;
  displayName: string;
  isPromote: boolean;
  communityId: number;
}

export interface CommunityRolesPrivileges {
  role: UserRole;
  privileges: UserPrivilege[];
}

import React from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { TextField as NatterTextField, type TextFieldProps as NatterTextFieldProps } from 'common/components/Inputs';

export type TextFieldProps<T extends FieldValues> = Omit<NatterTextFieldProps, 'onChange'> & {
  name: FieldPath<T>;
  onChange?: (value: string) => void;
};

export const TextField = <T extends FieldValues>({ name, onChange, hint, ...textFieldProps }: TextFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, type } = event.target;
    const newValue = value !== '' && type === 'number' ? Number(value) : value;

    // @ts-expect-error As we want to have number but HTMLInputElement value is always string
    onChange?.(newValue);
    field.onChange({ ...event, target: { ...event.target, value: newValue } });
  };

  return (
    <NatterTextField
      {...textFieldProps}
      {...field}
      value={field.value ?? ''}
      error={!!error?.message}
      hint={error?.message || hint}
      onChange={handleChange}
    />
  );
};

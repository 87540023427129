import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { assert, constantToLabel, getImageFormData } from 'common/utils';
import { BasicPaginationParams, PaginatedApiPayload, PaginatedApiPayloadV2 } from 'domain/Common';
import type {
  Community,
  CommunityFormValues,
  CommunityGuest,
  CommunityMember,
  CommunityRolesPrivileges,
  UpdateCommunityData,
} from 'domain/Community';
import { OrganizationMember, OrganizationMemberApiPayload } from 'domain/Organization';
import { UserRole } from 'domain/UserProfile';
import { map } from 'lodash';
import { getApiBaseUrl, mapToApiParams, transformPaginatedApiPayload } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { mapCommunityToApi } from '../community/mappers';
import type { GetCommunityMembers, GetCommunityMembersParams } from '../community/types';
import { mapOrganizationMemberFromApi } from './mappers';
import type {
  GetManagedOrganizationApiPayload,
  GetOrganizationMembersParams,
  GetRolesResponse,
  OrganizationMemberFormData,
  RemoveOrganizationMemberPayload,
  TransferCommunityPayload,
  UploadCommunityLogoPayload,
  WithOrganizationId,
} from './types';

export const organizationIdErrorMessage = 'Organization ID required';
export const communityIdErrorMessage = 'Community ID required';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  tagTypes: ['COMMUNITIES', 'COMMUNITY', 'COMMUNITY_MEMBERS', 'ORGANIZATION_MEMBERS'],
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 0 }),
  endpoints: (builder) => ({
    getManagedOrganizations: builder.query<GetManagedOrganizationApiPayload, void>({
      query: () => ({
        url: '/organization/managed',
      }),
    }),
    getOrganizationMembers: builder.query<PaginatedApiPayloadV2<OrganizationMember>, GetOrganizationMembersParams>({
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ userId }) => ({ type: 'ORGANIZATION_MEMBERS', id: userId }))
          : ['ORGANIZATION_MEMBERS'],
      query: ({ organizationId, searchPhrase, page, pageSize, sortBy, sortOrder }) => ({
        url: `/organization/${organizationId}/members`,
        params: {
          page,
          size: pageSize,
          sortParam: sortBy,
          sortDir: sortOrder,
          searchText: searchPhrase || undefined,
        },
      }),
      transformResponse: ({
        organizationMembers,
      }: {
        organizationMembers: PaginatedApiPayloadV2<OrganizationMemberApiPayload>;
      }) => ({
        data: organizationMembers.data.map(mapOrganizationMemberFromApi),
        totalCount: organizationMembers.totalCount,
      }),
    }),
    removeOrganizationMember: builder.mutation<void, RemoveOrganizationMemberPayload>({
      invalidatesTags: ['ORGANIZATION_MEMBERS'],
      query: ({ organizationId, userId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/remove-user`,
          method: 'DELETE',
          data: { userId },
        };
      },
    }),
    inviteOrganizationMember: builder.mutation<void, OrganizationMemberFormData>({
      query: ({ organizationId, email, role, communityIds }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/invite-user`,
          method: 'POST',
          data: { email, role, communityIds },
        };
      },
    }),
    updateOrganizationMemberAccess: builder.mutation<void, OrganizationMemberFormData>({
      invalidatesTags: (_result, _error, { userId }) => [{ type: 'ORGANIZATION_MEMBERS', id: userId }],
      query: ({ organizationId, userId, role, communityIds }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/update-access`,
          method: 'POST',
          data: { userId, role, communityIds },
        };
      },
    }),
    joinOrganization: builder.mutation<void, WithOrganizationId>({
      query: ({ organizationId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/join`,
          method: 'POST',
        };
      },
    }),
    getCommunitiesList: builder.query<PaginatedApiPayload<Community>, WithOrganizationId<BasicPaginationParams>>({
      providesTags: (result) =>
        result?.data.length ? result.data.map(({ id }) => ({ type: 'COMMUNITIES', id })) : ['COMMUNITIES'],
      query: ({ organizationId, searchPhrase, page, pageSize, sortBy, sortOrder }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/communities`,
          params: {
            keywords: searchPhrase,
            page,
            size: pageSize,
            sortParam: sortBy,
            sortDir: sortOrder,
          },
        };
      },
      transformResponse: transformPaginatedApiPayload,
    }),
    getCommunityById: builder.query<Community, { communityId: number }>({
      providesTags: (result) => (result ? [{ type: 'COMMUNITY', id: result.id }] : []),
      query: ({ communityId }) => ({
        url: `/community/${communityId}`,
      }),
    }),
    getCommunityRoles: builder.query<CommunityRolesPrivileges[], { communityId: number }>({
      query: ({ communityId }) => ({
        url: `/community/${communityId}/roles`,
      }),
      transformResponse: (result: { roles: CommunityRolesPrivileges[] }) => result.roles,
    }),
    getRoles: builder.query<UserRole[], void>({
      query: () => ({
        url: `/community/roles`,
      }),
      transformResponse: (result: GetRolesResponse) =>
        result.communityRoles.map((role) => ({ ...role, label: constantToLabel(role.name) })),
    }),
    transferCommunity: builder.mutation<void, TransferCommunityPayload>({
      invalidatesTags: (_result, error, { communityId }) =>
        error ? [] : ['COMMUNITIES', { type: 'COMMUNITY', id: communityId }],
      query: ({ organizationId, communityId, organizationOwnerEmail }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/transfer`,
          method: 'PATCH',
          data: {
            organizationOwnerEmail,
            communityId,
          },
        };
      },
    }),
    createCommunity: builder.mutation<Community, WithOrganizationId<{ values: CommunityFormValues }>>({
      invalidatesTags: ['COMMUNITIES'],
      query: ({ organizationId, values }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/community`,
          method: 'POST',
          data: mapCommunityToApi(values),
        };
      },
    }),
    updateCommunity: builder.mutation<Community, UpdateCommunityData>({
      invalidatesTags: (_result, _error, arg) => [
        { type: 'COMMUNITIES', id: arg.id },
        { type: 'COMMUNITY', id: arg.id },
      ],
      query: (data) => ({
        url: `/community/${data.id}`,
        method: 'PUT',
        data: mapCommunityToApi(data),
      }),
    }),
    uploadCommunityLogo: builder.mutation<
      Pick<Community, 'logoImageLink' | 'logoImageLinkSmall'>,
      UploadCommunityLogoPayload
    >({
      invalidatesTags: (_result, _error, { communityId }) =>
        _error
          ? []
          : [
              { type: 'COMMUNITIES', id: communityId },
              { type: 'COMMUNITY', id: communityId },
            ],
      query: ({ communityId, logo }) => ({
        url: `/community/${communityId}/uploadLogo`,
        method: 'POST',
        data: getImageFormData(logo),
      }),
    }),
    deleteCommunity: builder.mutation<void, WithOrganizationId<{ communityId: number }>>({
      invalidatesTags: (_result, _error, { communityId }) =>
        _error ? [] : ['COMMUNITIES', { type: 'COMMUNITY', id: communityId }],
      query: async ({ organizationId, communityId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/community/${communityId}`,
          method: 'DELETE',
        };
      },
    }),
    getCommunityMembers: builder.query<PaginatedApiPayload<CommunityMember>, GetCommunityMembersParams>({
      providesTags: (result) => (result?.data ? result.data.map(({ id }) => ({ type: 'COMMUNITY_MEMBERS', id })) : []),
      query: ({ page, pageSize, sortBy, sortOrder, showAdmins, tags, searchPhrase, communityId, roles }) => ({
        baseURL: getApiBaseUrl('v2'),
        url: `/community/${communityId}/members`,
        params: mapToApiParams({
          page,
          size: pageSize,
          sortParam: sortBy,
          sortDir: sortOrder,
          displayOnlyManagers: showAdmins,
          includeCallingUser: true,
          tagIds: map(tags, 'id'),
          roleIds: map(roles, 'id'),
          searchText: searchPhrase.length ? searchPhrase : undefined,
        }) as GetCommunityMembers,
      }),
      transformResponse: transformPaginatedApiPayload,
    }),
    removeCommunityMember: builder.mutation<void, { memberId: number; communityId: number | undefined }>({
      query: ({ communityId, memberId }) => {
        assert(communityId, communityIdErrorMessage);
        return {
          url: `/community/${communityId}/${memberId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['COMMUNITY_MEMBERS'],
    }),
    inviteGuestsToCommunity: builder.mutation<void, { guests: CommunityGuest[]; communityId: number | undefined }>({
      query: ({ communityId, guests }) => {
        assert(communityId, communityIdErrorMessage);
        return {
          url: `/community/${communityId}/members`,
          method: 'PUT',
          data: guests,
        };
      },
      invalidatesTags: ['COMMUNITY_MEMBERS'],
    }),
  }),
});

export const {
  useCreateCommunityMutation,
  useGetOrganizationMembersQuery,
  useRemoveOrganizationMemberMutation,
  useGetCommunitiesListQuery,
  useLazyGetCommunitiesListQuery,
  useGetCommunityByIdQuery,
  useGetCommunityRolesQuery,
  useGetRolesQuery,
  useTransferCommunityMutation,
  useDeleteCommunityMutation,
  useGetManagedOrganizationsQuery,
  useUpdateCommunityMutation,
  useUploadCommunityLogoMutation,
  useGetCommunityMembersQuery,
  useRemoveCommunityMemberMutation,
  useInviteGuestsToCommunityMutation,
  useInviteOrganizationMemberMutation,
  useUpdateOrganizationMemberAccessMutation,
  useJoinOrganizationMutation,
} = organizationApi;

import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'lodash/fp';
import type { RootState } from 'store';

import { eventApi } from './eventApi';

export const selectEventDetailsById = eventApi.endpoints.getEventDetails.select;

// TODO Replace with `selectEventDetailsById` only when event hash migration finished
export const selectActiveEventDetails = createSelector(
  (state: RootState) => selectEventDetailsById({ id: state.event.activeEventId! })(state),
  (eventDetails) => eventDetails?.data
);

export const selectActiveEventQuestions = createSelector(
  (state: RootState) => eventApi.endpoints.getEventQuestions.select({ id: state.event.activeEventId! })(state),
  (response) => response?.data
);

export const selectIsUserRegistered = createSelector(selectActiveEventDetails, prop('isUserRegistered'));

export const selectHasCompletedPreEventQuestions = createSelector(
  selectActiveEventDetails,
  prop('hasCompletedPreEventQuestions')
);

export const selectHasCompletedPostEventQuestions = createSelector(
  selectActiveEventDetails,
  prop('hasCompletedPostEventQuestions')
);

export const selectEventStatus = createSelector(selectActiveEventDetails, prop('status'));

export const selectEventName = createSelector(selectActiveEventDetails, prop('name'));

export const selectEventPin = createSelector(selectActiveEventDetails, prop('pin'));
export const selectEventPinEnabled = createSelector(selectActiveEventDetails, prop('isPinEnabled'));
export const selectEventCommunityId = createSelector(selectActiveEventDetails, prop('communityId'));

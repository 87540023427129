import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import type { ProgressTrackerState } from 'common/components/ProgressTracker';
import { createStep } from 'common/components/ProgressTracker/utils';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import { SurveyFormMode } from '../constants';
import { publishSurvey, surveysWizardActions, updateSurvey } from './surveyWizard.actions';

export type SurveyWizardState = {
  mode: SurveyFormMode | undefined;
  progressTracker: ProgressTrackerState<SetupSurveyProgressTrackerStep>;
  isPreviewOpen: boolean;
  isLoading: boolean;
};

export const initialState: SurveyWizardState = {
  mode: undefined,
  progressTracker: {
    activeStep: SetupSurveyProgressTrackerStep.Questions,
    steps: [
      createStep('Survey Questions', 'Manage questions for your Survey'),
      createStep('Scheduling', 'Set Timeline & Reminders'),
      createStep('Invite Participants', 'Invite Participants to this Survey'),
    ],
  },
  isPreviewOpen: false,
  isLoading: false,
};

export const surveysWizardReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(surveysWizardActions.progressTracker.setAllStepsCompleted, (state) => {
      state.progressTracker.steps = state.progressTracker.steps.map((step) => ({
        ...step,
        isDisabled: false,
        isInProgress: false,
        isCompleted: true,
      }));
    })
    .addCase(surveysWizardActions.reset, () => initialState)
    .addCase(surveysWizardActions.progressTracker.stepClicked, (state, { payload }) => {
      state.progressTracker.activeStep = payload;
    })
    .addCase(surveysWizardActions.progressTracker.stepCompleted, (state, { payload }) => {
      state.progressTracker.steps[payload].isCompleted = true;
    })
    .addCase(surveysWizardActions.navigationBar.previewButton.clicked, (state) => {
      state.isPreviewOpen = true;
    })
    .addCase(surveysWizardActions.surveyPreview.closeButton.clicked, (state) => {
      state.isPreviewOpen = false;
    })
    .addMatcher(isAnyOf(publishSurvey.pending, updateSurvey.pending), (state) => {
      state.isLoading = true;
    })
    .addMatcher(
      isAnyOf(publishSurvey.fulfilled, publishSurvey.rejected, updateSurvey.fulfilled, updateSurvey.rejected),
      (state) => {
        state.isLoading = false;
      }
    )
);

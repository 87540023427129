import { createSelector } from '@reduxjs/toolkit';
import { includes } from 'lodash/fp';
import type { RootState } from 'store';

import { SocketConnectivityStatus } from './types';

const selectRooms = (state: RootState) => state.socket.connectedRooms;
const selectRoomName = (state: RootState, roomName: string) => roomName;

export const selectIsConnectedToRoom = createSelector(selectRoomName, selectRooms, includes);

export const selectIsSocketConnected = (state: RootState) => state.socket.isConnected;
export const selectIsSocketReconnecting = (state: RootState) => state.socket.isReconnecting;
export const selectSocketDisconnectsCount = (state: RootState) => state.socket.connectionErrorsCount;
export const selectSocketConnectivityStatus = (state: RootState): SocketConnectivityStatus => {
  if (state.socket.connectionErrorsCount === 0) {
    return 'Stable';
  }
  return 'Unstable';
};

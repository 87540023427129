import React, { FC } from 'react';

import { Skeleton, Stack, Typography } from '@mui/material';
import { FilterIcon } from 'icons';

import { MultipleSelectFilter, MultipleSelectFilterOption } from './MultipleSelectFilter';
import { ResetFiltersButton } from './ResetFiltersButton';

export type FilterItem = {
  name: string;
  label: string;
  options: MultipleSelectFilterOption[];
};

export const Filters: FC<{
  items: FilterItem[];
  isLoading?: boolean;
}> = ({ items, isLoading }) => (
  <Stack direction="row" gap={1} flexWrap="wrap" width="100%" alignItems="center">
    <Stack direction="row" alignItems="center" color="grey.600" gap={0.5} pr={1}>
      <FilterIcon />
      <Typography variant="natter-text-sm" fontWeight={500}>
        Filter by:
      </Typography>
    </Stack>
    {isLoading ? (
      <Skeleton
        variant="rectangular"
        width={200}
        height={32}
        sx={{ borderRadius: 2 }}
        data-testid="Filters-LoadingSkeleton"
      />
    ) : (
      items.map(({ name, label, options }) => (
        <MultipleSelectFilter key={name} data-testid={`Filters-${label}`} name={name} label={label} options={options} />
      ))
    )}
    <ResetFiltersButton />
  </Stack>
);

import type { Community, CommunityApiPayload } from 'domain/Community';

export const mapCommunityFromApi = (data: CommunityApiPayload): Community => ({
  id: data.id,
  hash: data.hash,
  name: data.name,
  ownerId: data.ownerId,
  tagsCategories: data.tagsCategories,
  brandingColor: data.brandingColor ?? undefined,
  communityMembersCount: data.communityMembersCount,
  logoImageLink: data.logoImageLink ?? undefined,
  logoImageLinkSmall: data.logoImageLinkSmall ?? undefined,
  defaultEventImage: data.defaultEventImage,
  communitySettings: data.communitySettings ?? {
    eventsPerMonthLimit: 0,
    membersLimit: 0,
    usersPerEventLimit: 0,
  },
  slug: data.slug,
});

import { CommunityMember, CommunityMemberFormData } from 'domain/Community';
import { UserPrivilegeDetails } from 'domain/UserProfile';

export const mapMemberToApi = (
  currentMemberData: CommunityMember | undefined,
  data: Partial<CommunityMemberFormData>
): Partial<CommunityMember> => ({
  role: data.role ?? currentMemberData?.role,
  tagsCategories: data.tagsCategories?.length ? data.tagsCategories : currentMemberData?.tagsCategories,
  privileges: data.privileges?.map((p) => ({
    name: p,
    id: UserPrivilegeDetails[p].id,
  })),
});

import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { eventApi } from 'store/apis/event/eventApi';

import { eventWizardActions } from './eventWizard.actions';
import {
  createEventEffect,
  createEventEntitiesEffect,
  createEventFailedEffect,
  eventCreated,
  eventImageUploadFailedEffect,
  eventQuestionCreationFailedEffect,
  eventUpdated,
  eventVideoUploadFailedEffect,
  goToMyEventsEffect,
  updateEventEffect,
  updateEventFailedEffect,
} from './eventWizard.effects';

export const eventWizardListener = createListenerMiddleware<RootState>();

eventWizardListener.startListening({
  actionCreator: eventWizardActions.publishButton.clicked,
  effect: createEventEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.createEvent.matchFulfilled,
  effect: createEventEntitiesEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.createEvent.matchRejected,
  effect: createEventFailedEffect,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.eventLimitReachedModal.confirmedButton.clicked,
  effect: goToMyEventsEffect,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.eventCreated,
  effect: eventCreated,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.updateButton.clicked,
  effect: updateEventEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.updateEvent.matchFulfilled,
  effect: eventUpdated,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.updateEvent.matchRejected,
  effect: updateEventFailedEffect,
});

eventWizardListener.startListening({
  actionCreator: eventWizardActions.details.videoDropzone.uploadFailed,
  effect: eventVideoUploadFailedEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.postEventImage.matchRejected,
  effect: eventImageUploadFailedEffect,
});

eventWizardListener.startListening({
  matcher: eventApi.endpoints.createEventQuestions.matchRejected,
  effect: eventQuestionCreationFailedEffect,
});

import { Drawer, styled } from '@mui/material';
import { shadows } from 'common/theme';

export const StyledDrawer = styled(Drawer)(({ hideBackdrop }) => ({
  ...(hideBackdrop && {
    pointerEvents: 'none',
    '& .MuiPaper-root': {
      pointerEvents: 'initial',
      boxShadow: shadows.overlay,
      border: 'none',
    },
  }),
}));

import { EventRoomAttendee, EventRoomAttendeeApiPayload } from 'domain/event';

export const mapToEventRoomAttendeeFromApi = (data: Partial<EventRoomAttendeeApiPayload>): EventRoomAttendee => ({
  name: data.userName ?? '',
  category: data.userCategory,
  id: Number(data.userId),
  tagsCategories: data.tagsCategories ?? [],
  avatarLink: data.avatarLink ?? '',
  role: data.role ?? '',
  room: data.roomId ?? '',
});

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toBoolean, toTimestamp } from 'common/utils';
import { differenceInMilliseconds } from 'date-fns';
import { ActiveMeetingBroadcast, AgoraCloudPlayerInfo, BroadcastConnectionInfo, ClientRole } from 'domain/Broadcast';
import {
  EventRoom,
  EventRoomApiPayload,
  EventRoomAttendee,
  MeetingGuest,
  MeetingGuestApiPayload,
  TranscriptionMode,
} from 'domain/event';
import { mapEventRoomFromApi, mapToEventRoomAttendeeFromApi, mapToMeetingGuestFromApi } from 'event/mappers';
import { uniqBy } from 'lodash';
import { eventApi } from 'store/apis/event';

import {
  broadcastChatModerationToggled_WS,
  broadcastDemoted_WS,
  broadcastGuestDemoted_WS,
  broadcastGuestPromotedList_WS,
  broadcastGuestPromoted_WS,
  broadcastPromoted_WS,
  broadcastStarted_WS,
  broadcastStopped_WS,
  broadcastVideoViaUrlPaused_WS,
  broadcastVideoViaUrlResumed_WS,
  recordingStarted_WS,
  recordingStopped_WS,
  screensharingStarted_WS,
  screensharingStopped_WS,
  stageInvitationAccepted_WS,
  stageInvitationReceived_WS,
  stageInvitationRejected_WS,
  stageInvitationSent_WS,
} from './actions/broadcast.actions';
import {
  audienceCountUpdated_WS,
  bmmMeetingGuestHandDown_WS,
  bmmMeetingGuestHandUpList_WS,
  bmmMeetingGuestHandUp_WS,
  eventBigMeetingModeEnabled_WS,
  guestExitedMeeting_WS,
  meetingGuestHandDown_WS,
  meetingGuestHandUp_WS,
  meetingMetricsUpdated_WS,
  meetingStarted_WS,
} from './actions/meeting.actions';
import {
  addNewRoom_WS,
  cancelNatterSupport,
  guestJoinedRoom_WS,
  joinRoomAsSupport,
  joinRoom_WS,
  leaveRoomAsSupport,
  meetingBroadcastCountdownStarted_WS,
  meetingRoomClosed_WS,
  natteringStarted_WS,
  requestNatterSupport,
  roomExit_WS,
  roomSupportCancelled_WS,
  roomSupportJoined_WS,
  roomSupportRequestedList_WS,
  roomSupportRequested_WS,
  roomsCountUpdated_WS,
  submitCallFeedback,
  transcriptionStarted_WS,
  transcriptionStopped_WS,
  userDisconnectedList_WS,
} from './actions/room.actions';
import { ActiveRoomState, EventState } from './types/event.types';
import { findRoomById } from './utils';

export const initialEventState: EventState = {
  activeEventId: undefined,
  broadcast: null,
  serverTimeOffset: 0,
  activeRoom: null,
  rooms: [],
  roomsRequestingSupport: [],
  roomsCount: 0,
  pin: '',
  screensharingDetails: null,
  metrics: {
    conversations: null,
    dataPoints: null,
    ratingAverage: null,
  },
  audience: [],
  audienceCount: 0,
  speakers: [],
  participantsInvitedToStage: [],
  participantsWithRaisedHands: [],
  participantsDisconnected: [],
  stageInvitation: null,
  lastCallGuests: [],
  isJoiningNattering: false,
  isNatteringSkipped: false,
  natteringRoundEndTime: 0,
  isBigMeetingMode: false,
  showAudienceList: true,
  activePanelTab: 0,
  isWaitingForNattering: false,
  isBroadcastStopped: false,
};

const emptyBroadcast: ActiveMeetingBroadcast = {
  broadcastChatToken: '',
  channelId: '',
  cloudPlayer: null,
  isChatModerated: false,
  publisherAccessToken: null,
  role: ClientRole.Audience,
  screensharing: undefined,
  accessToken: undefined,
  isRecording: false,
};

const defaultActiveRoomState: ActiveRoomState = {
  id: '',
  isConversationFinished: false,
  isBroadcastCountdown: false,
  joinedAsSupport: false,
  showTranscriptionModal: false,
  isTranscribing: true,
};

const handleGuestExitMeeting = (attendee: MeetingGuest, state: EventState) => {
  const audienceIndex = state.audience.findIndex(({ id }) => id === attendee.id);
  if (audienceIndex > -1) {
    state.audience.splice(audienceIndex, 1);
  }

  const attendeeIndex = state.speakers.findIndex(({ id }) => id === attendee.id);
  if (attendeeIndex > -1) {
    state.speakers.splice(attendeeIndex, 1);
  }

  state.participantsDisconnected.push(attendee.id);
  handChangeHandler(state, false, attendee.id, attendee.room);
};

const handChangeHandler = (state: EventState, isHandRaised: boolean, userId: number, roomId?: string) => {
  if (isHandRaised) {
    if (state.broadcast) {
      state.participantsWithRaisedHands.push(userId);
    } else if (roomId) {
      state.roomsRequestingSupport.push(roomId);
    }
  } else if (state.broadcast) {
    state.participantsWithRaisedHands = state.participantsWithRaisedHands.filter((id) => id !== userId);
  } else if (roomId) {
    state.roomsRequestingSupport = state.roomsRequestingSupport.filter((id) => id !== roomId);
  }
};

const handChangeHandlerBMM = (state: EventState, isHandRaised: boolean, guestDataList: MeetingGuestApiPayload[]) => {
  guestDataList.forEach((guestData) => {
    const guest = mapToMeetingGuestFromApi(guestData);
    handChangeHandler(state, isHandRaised, guest.id, guest.room);
    const isInSpeakers = state.speakers.some((s) => s.id === guest.id);
    const indexOfAudience = state.audience.findIndex((s) => s.id === guest.id);
    const isInAudience = indexOfAudience > -1;
    const isMemberInState = isInSpeakers || isInAudience;
    if (!isMemberInState && isHandRaised) {
      state.audience.unshift(guest);
    }
  });
};

const joinRoomHandler = (state: EventState, payload: EventRoomApiPayload) => {
  const roomData = mapEventRoomFromApi(payload);
  const existingRoom = findRoomById(state.rooms, payload.id);
  if (existingRoom) {
    existingRoom.accessToken = roomData.accessToken;
    existingRoom.duration = roomData.duration;
    existingRoom.startTime = roomData.startTime;
    existingRoom.endTime = roomData.endTime;
    existingRoom.id = roomData.id;
    existingRoom.roomQuestionAssignments = roomData.roomQuestionAssignments;
  } else {
    state.rooms.push(roomData);
  }

  if (!state.activeRoom) {
    state.activeRoom = {
      ...defaultActiveRoomState,
      id: payload.id,
      showTranscriptionModal:
        payload.transcriptionMode === TranscriptionMode.OPTIONAL && !payload.hasSetTranscriptionPreference,
      isTranscribing: !!payload.isTranscribing,
    };
  }
};

const addNewRoomHandler = (state: EventState, payload: EventRoomApiPayload) => {
  const room = findRoomById(state.rooms, payload.id);
  if (room) {
    room.accessToken = payload.accessToken;
    room.endTime = payload.endTime;
    room.startTime = payload.startTime;
    room.roomQuestionAssignments = payload.roomQuestionAssignments ?? [];
    return;
  }
  state.rooms = uniqBy([...state.rooms, mapEventRoomFromApi(payload)], 'id');
};

const sortSpeakers = (state: EventState) => {
  state.speakers.sort((s) => (state.participantsWithRaisedHands.includes(s.id) ? -1 : 1));
};

export const eventSlice = createSlice({
  name: 'event',
  initialState: initialEventState,
  reducers: {
    resetEventState: () => initialEventState,
    clearPinValue: (state) => {
      state.pin = '';
    },
    addLastCallQuest: (state, { payload }: PayloadAction<EventRoomAttendee>) => {
      state.lastCallGuests = uniqBy([...state.lastCallGuests, payload], 'id');
    },
    addAudience: (state, action: PayloadAction<MeetingGuest[]>) => {
      const speakersIds = state.speakers.map((s) => s.id);
      const notSpeakers = action.payload.filter((a) => !speakersIds.includes(a.id));
      state.audience = uniqBy(notSpeakers.concat(state.audience), 'id');
    },
    addUserToAudience: (state, { payload }: PayloadAction<MeetingGuest>) => {
      state.audience.unshift(payload);
    },
    demoteUserToAudience: (state, { payload }: PayloadAction<{ newAudience: MeetingGuest[] }>) => {
      state.participantsInvitedToStage = [];
      if (state.isBigMeetingMode) {
        state.audience = payload.newAudience;
        state.participantsWithRaisedHands = [];
      }
    },
    removeUserFromAudience: (state, { payload }: PayloadAction<{ id: number }>) => {
      const indexOfAudience = state.audience.findIndex(({ id }) => id === payload.id);
      const isInAudience = indexOfAudience > -1;

      if (isInAudience) {
        state.audience.splice(indexOfAudience, 1);
      }
    },
    setDisconnectedParticipants: (state, action: PayloadAction<number[]>) => {
      state.participantsDisconnected = action.payload;
    },
    clearStageInvitation: (state) => {
      state.stageInvitation = null;
    },
    setCloudPlayer: (state, { payload }: PayloadAction<AgoraCloudPlayerInfo | null>) => {
      state.broadcast = {
        ...(state.broadcast ?? emptyBroadcast),
        cloudPlayer: payload,
      };
    },
    setUserHandRaisedForUser: (state, { payload }: PayloadAction<{ id?: number; handRaised: boolean }>) => {
      if (payload.id) {
        handChangeHandler(state, payload.handRaised, payload.id);
      }
    },
    skipNattering: (state) => {
      state.isJoiningNattering = false;
      state.isNatteringSkipped = true;
    },
    joinNattering: (state) => {
      state.isJoiningNattering = false;
      state.isNatteringSkipped = false;
    },
    addRoom: (state, { payload: { room } }: PayloadAction<{ room: EventRoom }>) => {
      state.rooms.unshift(room);
    },
    removeRoom: (state, { payload: { roomId } }: PayloadAction<{ roomId: string }>) => {
      state.rooms = state.rooms.filter((r) => r.id !== roomId);
    },
    updateRoom: (state, { payload: { room } }: PayloadAction<{ room: EventRoom }>) => {
      const roomIndex = state.rooms.findIndex(({ id }) => id === room.id);
      state.rooms[roomIndex] = room;
    },
    addParticipantsWithRaisedHand: (state, { payload }: PayloadAction<number[]>) => {
      state.participantsWithRaisedHands = [...new Set(state.participantsWithRaisedHands.concat(payload))];
    },
    toggleShowAudienceList: (state) => {
      state.showAudienceList = !state.showAudienceList;
    },
    setActivePanelTab: (state, { payload }: PayloadAction<number>) => {
      state.activePanelTab = payload;
    },
    hideTranscriptionModal: (state) => {
      if (!state.activeRoom) return;

      state.activeRoom.showTranscriptionModal = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(addNewRoom_WS, (state, { payload }) => {
        addNewRoomHandler(state, payload);
      })
      .addCase(joinRoom_WS, (state, { payload }) => {
        joinRoomHandler(state, payload);
      })
      .addCase(joinRoomAsSupport.fulfilled, (state, { meta }) => {
        state.activeRoom = {
          ...defaultActiveRoomState,
          id: meta.arg.room.id,
          joinedAsSupport: true,
        };

        const room = findRoomById(state.rooms, meta.arg.room.id);
        if (!room) {
          //in Big Meeting Mode its possible that the room is only present in search results
          state.rooms.unshift({
            ...meta.arg.room,
            roomQuestionAssignments: [],
          });
        }
      })
      .addCase(roomSupportJoined_WS, (state, { payload }) => {
        state.roomsRequestingSupport = state.roomsRequestingSupport.filter((roomId) => roomId !== payload.roomId);
        const room = findRoomById(state.rooms, payload.roomId);
        if (room) {
          const attendee = mapToEventRoomAttendeeFromApi(payload.user);
          room.attendees.push(attendee);
        }

        if (!state.activeRoom) return;

        state.activeRoom.isTranscribing = !!payload.isTranscribing;
      })
      .addCase(guestJoinedRoom_WS, (state) => {
        state.isWaitingForNattering = false;
      })
      .addCase(guestExitedMeeting_WS, (state, { payload }) => {
        const attendee = mapToMeetingGuestFromApi(payload);
        handleGuestExitMeeting(attendee, state);
      })
      .addCase(submitCallFeedback.fulfilled, (state) => {
        state.activeRoom = null;
        state.lastCallGuests = [];
      })
      .addCase(leaveRoomAsSupport.fulfilled, (state) => {
        if (state.isBigMeetingMode) {
          state.rooms = state.rooms.filter(
            (r) => r.id !== state.activeRoom?.id || state.roomsRequestingSupport.includes(r.id)
          );
        }
        state.activeRoom = null;
        state.lastCallGuests = [];
      })
      .addCase(meetingStarted_WS, (state, action) => {
        const offset = differenceInMilliseconds(new Date(), new Date(action.payload.serverNow));
        state.serverTimeOffset = offset;
      })
      .addCase(meetingRoomClosed_WS, (state, action) => {
        state.rooms = state.rooms.filter(({ id }) => id !== action.payload.id);
      })
      .addCase(meetingBroadcastCountdownStarted_WS, (state) => {
        if (state.activeRoom) {
          state.activeRoom.isBroadcastCountdown = true;
        }
      })
      .addCase(broadcastStarted_WS, (state, action: PayloadAction<BroadcastConnectionInfo>) => {
        const { accessToken, broadcastChatToken, channelId, isChatModerated, isRecording } = action.payload;
        state.broadcast = {
          ...(state.broadcast ?? emptyBroadcast),
          accessToken,
          broadcastChatToken,
          channelId,
          isChatModerated: toBoolean(isChatModerated),
          isRecording: toBoolean(isRecording),
        };

        if (state.activeRoom) {
          state.activeRoom.isBroadcastCountdown = false;
        }

        state.isNatteringSkipped = false;
        state.isJoiningNattering = false;
        state.isBroadcastStopped = false;
      })
      .addCase(natteringStarted_WS, (state, { payload: { natteringRoundEndTime } }) => {
        state.isJoiningNattering = true;
        state.natteringRoundEndTime = toTimestamp(natteringRoundEndTime);
        state.broadcast = null;
        state.rooms = [];
        state.audience = [];
        state.speakers = [];
      })
      .addCase(broadcastStopped_WS, (state) => {
        state.isBroadcastStopped = !!state.broadcast;
        state.broadcast = null;
        state.audience = [];
        state.speakers = [];
        state.participantsWithRaisedHands = [];
        state.participantsDisconnected = [];
        state.participantsInvitedToStage = [];
        state.isWaitingForNattering = true;
      })
      .addCase(roomExit_WS, (state) => {
        if (state.activeRoom) {
          state.activeRoom.isConversationFinished = true;
        }
      })
      .addCase(meetingMetricsUpdated_WS, (state, action) => {
        state.metrics = {
          conversations: action.payload.conversations,
          dataPoints: action.payload.dataPoints,
          ratingAverage: action.payload.ratingAverage,
        };
      })
      .addCase(screensharingStarted_WS, (state, action) => {
        state.screensharingDetails = {
          sharerName: action.payload.sharerName,
          sharerUID: Number(action.payload.sharerUID),
        };
      })
      .addCase(screensharingStopped_WS, (state) => {
        state.screensharingDetails = null;
      })
      .addCase(stageInvitationReceived_WS, (state, action) => {
        state.stageInvitation = action.payload;
      })
      .addCase(stageInvitationSent_WS, (state, { payload }) => {
        state.participantsInvitedToStage.push(Number(payload.invitedUserId));
      })
      .addCase(stageInvitationAccepted_WS, (state, { payload }) => {
        state.participantsInvitedToStage = state.participantsInvitedToStage.filter(
          (id) => id !== Number(payload.invitedUserId)
        );
      })
      .addCase(stageInvitationRejected_WS, (state, { payload }) => {
        state.participantsInvitedToStage = state.participantsInvitedToStage.filter(
          (id) => id !== Number(payload.invitedUserId)
        );
      })
      .addCase(broadcastGuestPromoted_WS, (state, action) => {
        const newSpeaker = mapToMeetingGuestFromApi(action.payload);
        state.speakers = uniqBy(state.speakers.concat([newSpeaker]), 'id');
        state.audience = state.audience.filter(({ id }) => id !== newSpeaker.id);
        sortSpeakers(state);
      })
      .addCase(broadcastGuestPromotedList_WS, (state, action) => {
        const newSpeakers = action.payload.map(mapToMeetingGuestFromApi);
        const newSpeakersIds = newSpeakers.map((s) => s.id);
        state.speakers = uniqBy(state.speakers.concat(newSpeakers), 'id');
        state.audience = state.audience.filter(({ id }) => !newSpeakersIds.includes(id));
        sortSpeakers(state);
      })
      .addCase(broadcastPromoted_WS, (state, action) => {
        state.broadcast = {
          ...(state.broadcast ?? emptyBroadcast),
          role: ClientRole.Host,
          publisherAccessToken: action.payload.publisherAccessToken,
        };
      })
      .addCase(broadcastDemoted_WS, (state) => {
        state.broadcast = {
          ...(state.broadcast ?? emptyBroadcast),
          role: ClientRole.Audience,
          publisherAccessToken: null,
        };
      })
      .addCase(broadcastGuestDemoted_WS, (state, { payload }) => {
        const speaker = mapToMeetingGuestFromApi(payload);
        state.speakers = state.speakers.filter(({ id }) => id !== speaker.id);

        if (!state.isBigMeetingMode) {
          state.audience.push(speaker);
        }
      })
      .addCase(meetingGuestHandUp_WS, (state, { payload }) => {
        handChangeHandler(state, true, Number(payload.id), payload.room);
        sortSpeakers(state);
      })
      .addCase(meetingGuestHandDown_WS, (state, { payload }) => {
        handChangeHandler(state, false, Number(payload.id), payload.room);
        sortSpeakers(state);
      })
      .addCase(bmmMeetingGuestHandUp_WS, (state, { payload }) => {
        handChangeHandlerBMM(state, true, [payload]);
      })
      .addCase(bmmMeetingGuestHandUpList_WS, (state, { payload }) => {
        handChangeHandlerBMM(state, true, payload);
      })
      .addCase(bmmMeetingGuestHandDown_WS, (state, { payload }) => {
        handChangeHandlerBMM(state, false, [payload]);
      })
      .addCase(requestNatterSupport.fulfilled, (state, { meta }) => {
        state.roomsRequestingSupport.push(meta.arg.roomId);
      })
      .addCase(cancelNatterSupport.fulfilled, (state, { meta }) => {
        state.roomsRequestingSupport = state.roomsRequestingSupport.filter((roomId) => roomId !== meta.arg.roomId);
      })
      .addCase(roomSupportRequested_WS, (state, { payload }) => {
        state.roomsRequestingSupport.push(payload.room.id);
      })
      .addCase(roomSupportRequestedList_WS, (state, { payload }) => {
        state.roomsRequestingSupport = state.roomsRequestingSupport.concat(payload.rooms.map(({ id }) => id));
      })
      .addCase(roomSupportCancelled_WS, (state, { payload }) => {
        state.roomsRequestingSupport = state.roomsRequestingSupport.filter((roomId) => roomId !== payload.roomId);
      })
      .addCase(broadcastChatModerationToggled_WS, (state, { payload }) => {
        state.broadcast = {
          ...(state.broadcast ?? emptyBroadcast),
          isChatModerated: payload.isChatModerated,
        };
      })
      .addCase(broadcastVideoViaUrlPaused_WS, (state) => {
        if (state.broadcast?.cloudPlayer) {
          state.broadcast.cloudPlayer.isPlaying = false;
        }
      })
      .addCase(broadcastVideoViaUrlResumed_WS, (state) => {
        if (state.broadcast?.cloudPlayer) {
          state.broadcast.cloudPlayer.isPlaying = true;
        }
      })
      .addCase(eventBigMeetingModeEnabled_WS, (state) => {
        state.isBigMeetingMode = true;
        state.rooms = state.rooms.filter((r) => r.id === state.activeRoom?.id);
        state.audience = state.audience.filter((a) => state.participantsWithRaisedHands.includes(a.id));
      })
      .addCase(audienceCountUpdated_WS, (state, { payload }) => {
        state.audienceCount = payload.audienceCount;
      })
      .addCase(roomsCountUpdated_WS, (state, { payload }) => {
        state.roomsCount = payload.roomsCount;
      })
      .addCase(userDisconnectedList_WS, (state, { payload }) => {
        state.participantsDisconnected = state.participantsDisconnected.concat(payload.map(({ userId }) => userId));
      })
      .addCase(recordingStarted_WS, (state) => {
        if (state.broadcast) {
          state.broadcast.isRecording = true;
        }
      })
      .addCase(recordingStopped_WS, (state) => {
        if (state.broadcast) {
          state.broadcast.isRecording = false;
        }
      })
      .addCase(transcriptionStarted_WS, (state) => {
        if (!state.activeRoom) return;

        state.activeRoom.isTranscribing = true;
      })
      .addCase(transcriptionStopped_WS, (state) => {
        if (!state.activeRoom) return;

        state.activeRoom.isTranscribing = false;
      })
      .addMatcher(eventApi.endpoints.getEventDetails.matchFulfilled, (state, { meta }) => {
        state.activeEventId = meta.arg.originalArgs.id;
      })
      .addMatcher(eventApi.endpoints.validateEventPin.matchFulfilled, (state, { meta }) => {
        state.pin = meta.arg.originalArgs.pin;
      })
      .addMatcher(eventApi.endpoints.validateEventPin.matchRejected, (state, { meta }) => {
        state.pin = meta.arg.originalArgs.pin;
      }),
});

import { createSelector } from '@reduxjs/toolkit';
import { find } from 'lodash';
import { prop } from 'lodash/fp';
import type { RootState } from 'store';
import { selectActiveCommunityId } from 'store/features/community';

import { communityApi } from './communityApi';

const selectRootState = (state: RootState) => state;

// My Community
export const selectMyCommunities = communityApi.endpoints.getMyCommunities.select();
export const selectMyCommunity = createSelector(
  [selectActiveCommunityId, selectMyCommunities],
  (activeCommunityId, myCommunitiesDataResult) =>
    activeCommunityId ? find(myCommunitiesDataResult?.data?.myCommunities, { id: activeCommunityId }) : undefined
);

export const selectMyCommunityTagsCategories = createSelector(
  selectMyCommunity,
  (myCommunity) => myCommunity?.tagsCategories ?? []
);
export const selectMyCommunityId = createSelector(selectMyCommunity, prop('id'));
export const selectMyCommunityHash = createSelector(selectMyCommunity, prop('hash'));
export const selectMyCommunityName = createSelector(selectMyCommunity, prop('name'));
export const selectCommunitySettings = createSelector(selectMyCommunity, prop('communitySettings'));

export const selectHasNoCommunity = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.data?.hasNoCommunity
);
export const selectMyCommunityIsLoading = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.isLoading
);
export const selectMyCommunityIsError = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.isError
);
export const selectMyCommunitiesIds = createSelector(selectMyCommunities, (myCommunityDataResult) =>
  myCommunityDataResult?.data?.myCommunities.map((c) => c.id)
);

// Community Member
export const selectCommunityMemberByCommunityId = (state: RootState, id: number | undefined) =>
  communityApi.endpoints.getCurrentCommunityMember.select({ communityId: id })(state)?.data;

export const selectCurrentCommunityMember = createSelector(selectRootState, selectMyCommunityId, (state, id) =>
  selectCommunityMemberByCommunityId(state, id)
);

export const selectCurrentCommunityMemberIsLoading = createSelector(
  selectRootState,
  selectMyCommunityId,
  (state, id) => communityApi.endpoints.getCurrentCommunityMember.select({ communityId: id })(state).isLoading
);

export const selectCurrentCommunityMemberTags = createSelector(
  selectCurrentCommunityMember,
  (member) => member?.tagsCategories ?? []
);
export const selectCurrentCommunityMemberSocials = createSelector(
  selectRootState,
  selectMyCommunityId,
  (state, id) => selectCommunityMemberByCommunityId(state, id)?.socials ?? {}
);
export const selectAvailableTagsCategories = (state: RootState) =>
  communityApi.endpoints.getAvailableTagsCategories.select()(state).data ?? [];

export const selectCommunityMemberConfigurationInfo = createSelector(
  selectMyCommunity,
  selectCurrentCommunityMember,
  selectCurrentCommunityMemberIsLoading,
  (myCommunity, communityMember, isLoading) => {
    const isRegistered = !!communityMember;
    const isRoleConfigured = !!communityMember?.role;
    const isTagsConfigured = !!myCommunity?.tagsCategories?.every(
      (category) =>
        !category.isSingleSelection || communityMember?.tagsCategories.find(({ id }) => id === category.id)?.tags.length
    );

    const isAccountConfigured = !!(
      communityMember &&
      communityMember.displayName &&
      isRoleConfigured &&
      isTagsConfigured
    );

    return {
      isRoleConfigured,
      isTagsConfigured,
      isAccountConfigured,
      isRegistered,
      isLoading,
    };
  }
);

export const selectCommunityBySlug = (slug: string | undefined | null) =>
  createSelector(selectMyCommunities, (myCommunitiesDataResult) =>
    slug ? find(myCommunitiesDataResult?.data?.myCommunities, { slug }) : undefined
  );

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { goBack, push } from 'redux-first-history';
import type { CallHistoryMethodAction } from 'redux-first-history/build/es6/actions';
import { AppRoutes } from 'router';
import type { RootState } from 'store';
import type { ReportSource } from 'store/apis/insightsReport';
import { selectLocationState } from 'store/features/router';

import { selectIsSimilarReportSourcesStep, selectSelectedInsightsReportType } from './insightsReportsWizard.selectors';
import { setInsightsReportsWizardStep } from './insightsReportsWizardSlice.actions';

export const exitWizardAction = createAsyncThunk<CallHistoryMethodAction, { shouldGoBack?: boolean } | undefined>(
  '[Insights Reports Wizard NavigationBar] exit button clicked',
  ({ shouldGoBack = true } = {}, { dispatch, getState }) => {
    const state = getState() as RootState;
    const locationState = selectLocationState(state);

    if (locationState && shouldGoBack) {
      return dispatch(goBack());
    }

    const selectedInsightsReportType = selectSelectedInsightsReportType(state);

    switch (selectedInsightsReportType) {
      case 'Survey':
        return dispatch(push(AppRoutes.InsightsReports + AppRoutes.InsightsReportsSurveys));

      case 'Event':
      default:
        return dispatch(push(AppRoutes.InsightsReports + AppRoutes.InsightsReportsEvents));
    }
  }
);

export const backButtonClickedAction = createAsyncThunk(
  '[Insights Reports Wizard NavigationBar] back button clicked',
  (_args, { getState, dispatch }) => {
    const state = getState() as RootState;
    const isSimilarReportSourcesStep = selectIsSimilarReportSourcesStep(state);

    if (isSimilarReportSourcesStep) {
      dispatch(setInsightsReportsWizardStep('sources'));
    } else {
      dispatch(push(AppRoutes.InsightsReportsWizard));
    }
  }
);

export const insightsReportsWizardActions = {
  exit: exitWizardAction,
  actionButton: {
    backButton: {
      clicked: backButtonClickedAction,
    },
    nextButton: {
      clicked: createAction('[Insights Reports Wizard ActionButton] next button clicked'),
    },
  },
  reportSourcesTable: {
    reportSourceSelected: createAction<ReportSource | undefined>(
      '[Insights Reports Wizard ReportSourcesTable] report source selected'
    ),
  },
  similarReportSourcesTable: {
    selectAllCheckboxToggled: createAction<{ isChecked: boolean; similarReportSources: ReportSource[] }>(
      '[Insights Reports Wizard SimilarReportSourcesTable] select all checkbox toggled'
    ),
    reportSourceSelected: createAction<ReportSource>(
      '[Insights Reports Wizard ReportSourcesTable] similar report sources selected'
    ),
    similarReportSourcesFetched: createAction<ReportSource[]>(
      '[Insights Reports Wizard ReportSourcesTable] similar report sources fetched'
    ),
  },
};

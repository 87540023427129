import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { DialogTitle } from 'common/components/_legacy/DialogTitle';
import { TagsSelect } from 'common/components/_legacy/Form';
import { filterSelectedTags } from 'community/mappers';
import { tagsSelectionValidation } from 'community/pages/CommunitySettings/validators';
import { TagsCategory } from 'domain/Common';
import { array, object } from 'yup';

const schema = object({
  tagsCategories: array()
    .of(object({ tags: tagsSelectionValidation }).required())
    .required(),
});

const emptyTagsCategories: TagsCategory[] = [];

export const UserTagsModal: FC<{
  title: string;
  description?: string;
  open: boolean;
  tagsCategories: TagsCategory[] | undefined;
  isLoading?: boolean;
  isSuccess?: boolean;
  handleClose(): void;
  onUpdateTags?(tagsCategories?: TagsCategory[]): void;
}> = ({
  title = '',
  description,
  tagsCategories = emptyTagsCategories,
  open,
  isSuccess = false,
  isLoading = false,
  handleClose,
  onUpdateTags,
}) => {
  const filteredTagsCategories = useMemo(
    () => tagsCategories.filter((category) => !!category.tags.length),
    [tagsCategories]
  );
  const [internalOpen, setInternalOpen] = useState(open);
  const disableEdit = !onUpdateTags;

  // @ts-expect-error TODO add Schema
  const methods = useForm<unknown>({
    resolver: yupResolver(schema),
    defaultValues: {
      tagsCategories: filteredTagsCategories,
    },
  });
  const { reset } = methods;

  const handleCancel = (): void => {
    reset({ tagsCategories: filteredTagsCategories });
    closeModal();
  };

  const closeModal = () => setInternalOpen(false);

  const handleConfirm = methods.handleSubmit((data) => {
    // @ts-expect-error TODO: add type check
    onUpdateTags?.(filterSelectedTags(data.tagsCategories));
  });

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  useEffect(() => {
    reset({ tagsCategories: filteredTagsCategories });
  }, [filteredTagsCategories, reset]);

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  return (
    <Dialog
      open={internalOpen}
      onClose={closeModal}
      onTransitionExited={() => handleClose()}
      data-testid="UserTagsModal"
    >
      <DialogTitle onClose={closeModal}>{title}</DialogTitle>
      {/* @ts-expect-error TODO add Schema*/}
      <FormProvider {...methods}>
        <DialogContent>
          {!!description && <DialogContentText>{description}</DialogContentText>}
          <TagsSelect name="tagsCategories" disableEdit={disableEdit} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleCancel}>
            {disableEdit ? 'Close' : 'Cancel'}
          </Button>
          {!disableEdit && (
            <Button variant="contained" color="primary" onClick={handleConfirm} isLoading={isLoading}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

import { Step } from 'common/components/TaskStepper';
import { exhaustiveCheck } from 'common/utils';
import i18n from 'i18n';
import { EventWizardStep } from 'pages/EventWizard/constants';

import { EventWizardMode } from '../types';

export const getEventWizardInitialSteps = (mode: EventWizardMode): Step[] => {
  const steps = i18n.t('event:wizard.steps', { returnObjects: true }) as { title: string; description: string }[];

  switch (mode) {
    case 'create':
      return steps.map((step, i) => ({
        ...step,
        status: i === 0 ? 'IN_PROGRESS' : 'NOT_STARTED',
      }));
    case 'repeat':
    case 'edit':
      return steps.map((step, i) => ({
        ...step,
        status: i === EventWizardStep.EventDetails ? 'IN_PROGRESS' : 'COMPLETED',
      }));
    default:
      exhaustiveCheck(mode);
  }
};

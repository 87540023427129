import React, { FC, ReactNode } from 'react';

import { Stack, Toolbar, Typography } from '@mui/material';
import { ButtonMenu, MenuItemType } from 'common/components';
import { DeleteIcon, LogoutIcon } from 'icons';

import { NavigationBarToolbarWrapper } from './NavigationBarToolbar.styled';

type Props = {
  title?: ReactNode;
  onExit?(): void;
  onDelete?(): void;
  menuItems?: MenuItemType[];
  extraButtons?: ReactNode;
  action?: ReactNode;
  status?: ReactNode;
};

export const NavigationBarToolbar: FC<Props> = ({
  title,
  onExit,
  onDelete,
  menuItems: items = [],
  extraButtons,
  action,
  status,
  children,
}) => {
  const menuItems: MenuItemType[] = [
    ...items,
    onExit && {
      text: 'Exit',
      icon: <LogoutIcon fontSize="small" />,
      onClick: onExit,
    },
    onDelete && {
      text: 'Delete',
      icon: <DeleteIcon fontSize="small" />,
      severity: 'danger',
      onClick: onDelete,
    },
  ].filter(Boolean) as MenuItemType[];

  return (
    <Toolbar variant="dense" sx={{ pb: 1.25 }}>
      <NavigationBarToolbarWrapper>
        {typeof title === 'string' ? (
          <Typography component="h1" variant="natter-text-sm" fontWeight={500}>
            {title}
          </Typography>
        ) : (
          title
        )}
        <Stack direction="row" ml="auto" gap={2} alignItems="center">
          <Stack direction="row" gap={1} alignItems="center" position="relative">
            {children}
            {status}
            {extraButtons}
            {action}

            {!!menuItems.length && <ButtonMenu size="medium" items={menuItems} />}
          </Stack>
        </Stack>
      </NavigationBarToolbarWrapper>
    </Toolbar>
  );
};

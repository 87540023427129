import React, { FC } from 'react';

import { TooltipProps as MuiTooltipProps, Tooltip } from '../../Tooltip';
import { IconButton, IconButtonProps } from '../IconButton';

export type IconButtonWithTooltipProps = IconButtonProps &
  Pick<MuiTooltipProps, 'title'> & {
    TooltipProps?: Omit<MuiTooltipProps, 'children' | 'title'>;
  };

export const IconButtonWithTooltip: FC<IconButtonWithTooltipProps> = ({ title, TooltipProps, ...iconButtonProps }) => (
  <Tooltip title={title} {...TooltipProps}>
    <IconButton aria-label={title} {...iconButtonProps} />
  </Tooltip>
);

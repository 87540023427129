import type { Components, Theme } from '@mui/material';

import { shadows } from '../shadows';

export const MuiCard: Components<Theme>['MuiCard'] = {
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      borderRadius: 8,
      '&:hover': {
        boxShadow: shadows[4],
      },
      '&[data-active]': {
        border: '1px solid',
        borderColor: palette.primary.main,
      },
    }),
  },
};

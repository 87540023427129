import { Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme: { palette, spacing } }) => ({
      color: palette.grey[500],
      padding: spacing(0),

      '&&.Mui-disabled': {
        color: palette.text.disabled,
      },
    }),
  },
};

import { Tag, TagsCategory } from 'domain/Common';

export const filterSelectedTags = (tagsCategories: TagsCategory[]): TagsCategory[] =>
  tagsCategories.map((c) => ({
    ...c,
    tags: c.tags
      .filter((t) => t.isSelected)
      .map<Tag>((t) => ({
        id: t.id,
        name: t.name,
      })),
  }));

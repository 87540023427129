import React, { ChangeEventHandler, FC, FocusEventHandler } from 'react';
import { useController } from 'react-hook-form';

import { InlineEditorInputProps, InlineEditorInput as InlineEditorInputRaw } from 'common/components/Inputs';

type Props = Omit<InlineEditorInputProps, 'onChange'> & {
  name: string;
  onChange?(value: string): void;
};

export const InlineEditorInput: FC<Props> = ({ name, defaultValue, onBlur, onChange: onChangeEffect, ...props }) => {
  const {
    field: { value, onChange, ref, onBlur: formOnBlur, disabled },
    fieldState: { error },
  } = useController({ name, defaultValue });

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    formOnBlur();
    onBlur?.(event);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event);
    onChangeEffect?.(event.target.value);
  };

  return (
    <InlineEditorInputRaw
      error={!!error}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      inputRef={ref}
      {...props}
      onBlur={handleBlur}
    />
  );
};

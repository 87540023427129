import React, { FC, MouseEventHandler, ReactNode } from 'react';

import { withPopover } from 'common/hocs';
import { MoreVerticalIcon } from 'icons';

import { IconButtonProps } from '../IconButton';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';

type Props = IconButtonProps & {
  Icon?: ReactNode;
  onClick: MouseEventHandler<HTMLElement>;
  isActive: boolean;
  title?: string;
};

const Button: FC<Props> = ({
  variant = 'outlined',
  Icon = <MoreVerticalIcon />,
  color = variant === 'text' ? 'text' : undefined,
  isActive,
  title = '',
  ...props
}) => (
  <IconButtonWithTooltip
    title={title}
    color={color}
    variant={variant}
    className={isActive ? 'active' : undefined}
    {...props}
  >
    {Icon}
  </IconButtonWithTooltip>
);

export const IconButtonWithPopover = withPopover(Button);

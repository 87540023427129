import React, { FC, FocusEventHandler } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { InlineEditorInputProps } from 'common/components/Inputs';
import { InferType, StringSchema, object } from 'yup';

import { InlineEditorInput } from '../ReactHookForm/InlineEditorInput';
import { StyledForm } from './InlineEditor.styled';

type Props<S extends StringSchema> = {
  schema: S;
  name?: string;
  onSubmit?(value: InferType<S>): void;
  onCancel?(): void;
  onChange?(value: string): void;
} & Omit<InlineEditorInputProps, 'onChange'>;

export const InlineEditor: FC<Props<StringSchema>> = ({
  schema,
  name = 'name',
  defaultValue,
  onSubmit: handleSubmit,
  onCancel,
  onBlur,
  ...props
}) => {
  const methods = useForm({
    resolver: yupResolver(object({ [name]: schema })),
  });

  const onSubmit = methods.handleSubmit(
    (data) => {
      const value = data[name];
      if (value === defaultValue) {
        onCancel?.();
        return;
      }

      handleSubmit?.(value);
    },
    (errors) => console.error('InlineEditorInput validation', errors)
  );

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    onSubmit();
    onBlur?.(event);
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={onSubmit}>
        <InlineEditorInput name={name} defaultValue={defaultValue} onBlur={handleBlur} {...props} />
      </StyledForm>
    </FormProvider>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { ProgressTrackerState } from 'common/components';
import { identity, pipe, prop } from 'lodash/fp';
import type { RootState } from 'store';
import { selectSurveyId } from 'store/apis/survey';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import type { SurveyWizardState } from './surveyWizard.reducer';

const selectSurveysWizardState = (state: RootState) => state.surveyWizard;

export const selectIsEditMode = createSelector(selectSurveyId, identity, Boolean);
export const selectIsPreviewOpen = createSelector(selectSurveysWizardState, prop('isPreviewOpen'));
export const selectIsLoading = createSelector(selectSurveysWizardState, prop('isLoading'));

export const selectProgressTracker = pipe<RootState[], SurveyWizardState, ProgressTrackerState>(
  selectSurveysWizardState,
  prop('progressTracker')
);
export const selectActiveStep = pipe<RootState[], ProgressTrackerState, SetupSurveyProgressTrackerStep>(
  selectProgressTracker,
  prop('activeStep')
);
export const selectSteps = pipe<RootState[], ProgressTrackerState, ProgressTrackerState['steps']>(
  selectProgressTracker,
  prop('steps')
);

export const selectIsFirstStep = createSelector(
  selectActiveStep,
  (activeStep) => activeStep === SetupSurveyProgressTrackerStep.Questions
);

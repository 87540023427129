import type { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette, spacing, shape } }) => ({
      padding: spacing(1.75, 2),
      borderRadius: shape.borderRadius * 2,

      '.MuiOutlinedInput-notchedOutline': {
        borderColor: palette.grey[300],

        legend: {
          fontSize: '12px',
        },
      },

      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[600],
        },
      },

      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
          borderWidth: 1,
        },
      },

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.error.main,
        },
      },

      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[300],
        },
      },
    }),
    sizeSmall: ({ theme: { shape } }) => ({
      borderRadius: shape.borderRadius,
    }),
  },
};

import { assert, exhaustiveCheck } from 'common/utils';
import {
  CreateBasicQuestionRequest,
  CreateMultipleChoiceSingleSelectQuestionRequest,
  CreateQuestionRequest,
  CreateQuestionVariants,
  CreateRankingQuestionRequest,
  CreateSliderQuestionRequest,
} from 'domain/question';

import type { QuestionType } from '../schemas';

export const mapTopicQuestionToApi = <AssignmentType = string>(
  question: QuestionType,
  assignmentType: AssignmentType
) => ({
  question: { title: question.title, type: CreateQuestionVariants.TextQuestion },
  isObligatory: question.isObligatory,
  assignmentType,
  postNatteringQuestions: question.choices?.length
    ? [{ question: mapQuestionToCreateQuestionRequest(question), isObligatory: question.isObligatory }]
    : [],
});

export const mapQuestionToApi = <AssignmentType = string>(question: QuestionType, assignmentType: AssignmentType) => ({
  question: mapQuestionToCreateQuestionRequest(question),
  isObligatory: question.isObligatory,
  assignmentType,
});

const mapQuestionToCreateQuestionRequest = (question: QuestionType): CreateQuestionRequest => {
  assert(question.format, 'Question format value missing!');
  const createQuestionType = CreateQuestionVariants[question.format];

  const questionBase: CreateBasicQuestionRequest = {
    title: question.title,
    type: createQuestionType,
  };

  switch (createQuestionType) {
    case CreateQuestionVariants.TextQuestion:
    case CreateQuestionVariants.NPSQuestion:
      return questionBase;
    case CreateQuestionVariants.MultipleChoiceMultipleSelectQuestion:
    case CreateQuestionVariants.MultipleChoiceSingleSelectQuestion: {
      assert(question.choices, 'Property "choices" missing!');
      const multipleChoiceSingleSelectQuestion: CreateMultipleChoiceSingleSelectQuestionRequest = {
        ...questionBase,
        choices: question.choices.map((c) => c.value),
      };
      return multipleChoiceSingleSelectQuestion;
    }
    case CreateQuestionVariants.RankingQuestion: {
      assert(question.choices, 'Property "choices" missing!');
      const rankingQuestion: CreateRankingQuestionRequest = {
        ...questionBase,
        choices: question.choices.map((c) => c.value),
        randomizeChoices: !!question.randomizeChoices,
      };
      return rankingQuestion;
    }
    case CreateQuestionVariants.SliderQuestion: {
      const rankingQuestion: CreateSliderQuestionRequest = {
        ...questionBase,
        incrementValue: question.incrementValue ?? 1,
        maximumValue: question.maximumValue ?? 100,
        maximumValueLabel: question.maximumValueLabel ?? '',
        minimumValue: question.minimumValue ?? 0,
        minimumValueLabel: question.minimumValueLabel ?? '',
      };
      return rankingQuestion;
    }
    default:
      exhaustiveCheck(createQuestionType);
  }
};

import React, { FC } from 'react';

import { Paper, Stack } from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { SectionHeader } from 'common/layout';
import { Tag } from 'domain/Common';

import { MemberTag, MemberTagProps } from '../MemberTag';

export type MemberTagCategoryProps = {
  title: string;
  required?: boolean;
  tags: Tag[];
  error?: string;
  onTagSelect: MemberTagProps['onSelect'];
};

export const MemberTagCategory: FC<MemberTagCategoryProps> = ({
  title,
  required,
  tags,
  error,
  onTagSelect: handleTagSelect,
}) => (
  <Paper sx={{ p: 2 }}>
    <Stack gap={1}>
      <SectionHeader title={title} required={required} />
      <Stack>
        <Stack direction="row" gap={1} flexWrap="wrap">
          {tags.map((tag, index) => (
            <MemberTag key={index} tag={tag} onSelect={handleTagSelect} />
          ))}
        </Stack>

        {error && <FormFieldHint error>{error}</FormFieldHint>}
      </Stack>
    </Stack>
  </Paper>
);

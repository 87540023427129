import React, { FC } from 'react';

import { AddIcon } from 'icons';

import { Button, type ButtonProps } from '../Button';

type AddButtonProps = Pick<ButtonProps, 'onClick' | 'variant' | 'fullWidth' | 'disabled'>;

export const AddButton: FC<AddButtonProps> = ({ variant = 'outlined', fullWidth = true, ...props }) => (
  <Button variant={variant} startIcon={<AddIcon />} fullWidth={fullWidth} {...props} />
);

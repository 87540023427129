import { createApi, retry } from '@reduxjs/toolkit/query/react';
import type { ImageFile } from 'common/components/ImageDropzoneWithPreview/types';
import { ERROR_MESSAGES } from 'common/constants';
import { assert, getImageFormData } from 'common/utils';
import { mapMemberToApi } from 'community/mappers';
import type { BasicPaginationParams, PaginatedApiPayload, TagsCategory } from 'domain/Common';
import type {
  Community,
  CommunityAdminToggleRequest,
  CommunityApiPayload,
  CommunityFormValues,
  CommunityGuest,
  CommunityMember,
  CommunityMemberFormData,
  CommunityMemberSocials,
  CommunitySettings,
  UpdateCommunityData,
} from 'domain/Community';
import { flow, map, orderBy } from 'lodash/fp';
import { getApiBaseUrl, mapToApiParams, transformPaginatedApiPayload } from 'modules/api/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import { attachmentsApi } from '../attachments';
import { mapCommunityFromApi, mapCommunityToApi } from './mappers';
import type {
  EventValidationResult,
  GetCommunityAuthenticationMethodsParams,
  GetCommunityAuthenticationMethodsResponse,
  GetCommunityMemberConnections,
  GetCommunityMembers,
  GetCommunityMembersParams,
  GetMyCommunitiesPayload,
  UpdateCommunityBrandingPayload,
} from './types';

export const communityApi = createApi({
  reducerPath: 'communityApi',
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 0 }),
  tagTypes: ['MY_COMMUNITIES', 'COMMUNITY_MEMBER', 'ALL_COMMUNITY_MEMBERS'],
  endpoints: (builder) => ({
    createCommunity: builder.mutation<{ id: number }, CommunityFormValues>({
      query: (data) => ({
        url: `/community`,
        method: 'POST',
        data: mapCommunityToApi(data),
      }),
    }),
    getMyCommunities: builder.query<GetMyCommunitiesPayload, void>({
      keepUnusedDataFor: 600,
      query: () => `/community/me/joined`,
      providesTags: ['MY_COMMUNITIES'],
      transformResponse: (response: PaginatedApiPayload<CommunityApiPayload>) => {
        const { data = [], totalCount } = transformPaginatedApiPayload(response);
        return {
          myCommunities: flow(
            map(mapCommunityFromApi),
            orderBy(({ name }) => name.toLocaleLowerCase(), 'asc')
          )(data) as Community[],
          hasNoCommunity: !totalCount,
        };
      },
    }),
    uploadLogo: builder.mutation<
      Pick<Community, 'logoImageLink' | 'logoImageLinkSmall'>,
      { logo: ImageFile; communityId: number }
    >({
      query: ({ communityId, logo }) => ({
        url: `/community/${communityId}/uploadLogo`,
        method: 'POST',
        data: getImageFormData(logo),
      }),
      invalidatesTags: ['MY_COMMUNITIES'],
    }),
    updateMyCommunity: builder.mutation<Community, UpdateCommunityData>({
      query: (data) => ({
        url: `/community/${data.id}`,
        method: 'PUT',
        data: mapCommunityToApi(data),
      }),
      invalidatesTags: ['MY_COMMUNITIES', 'COMMUNITY_MEMBER'],
    }),
    getCommunityMembers: builder.query<PaginatedApiPayload<CommunityMember>, GetCommunityMembersParams>({
      query: ({ page, pageSize, sortBy, sortOrder, showAdmins, tags, searchPhrase, communityId, roles }) => ({
        baseURL: getApiBaseUrl('v2'),
        url: `/community/${communityId}/members`,
        params: mapToApiParams({
          page,
          size: pageSize,
          sortParam: sortBy,
          sortDir: sortOrder,
          // TODO change to displayOnlyAdmins after BE is ready
          displayOnlyManagers: showAdmins,
          includeCallingUser: true,
          tagIds: map('id')(tags),
          roleIds: map('id')(roles),
          searchText: searchPhrase.length ? searchPhrase : undefined,
        }) as GetCommunityMembers,
      }),
      providesTags: (result) =>
        result?.data
          ? [...result.data.map(({ id }) => ({ type: 'ALL_COMMUNITY_MEMBERS' as const, id })), 'ALL_COMMUNITY_MEMBERS']
          : [],
    }),
    getMyConnections: builder.query<
      PaginatedApiPayload<CommunityMember>,
      BasicPaginationParams & { communityId: number }
    >({
      query: ({ communityId, page, pageSize, searchPhrase }) => ({
        baseURL: getApiBaseUrl('v2'),
        url: `/community/${communityId}/connections`,
        params: {
          page,
          size: pageSize,
          searchText: searchPhrase?.length ? searchPhrase : undefined,
        } as GetCommunityMemberConnections,
      }),
    }),
    inviteGuestsToCommunity: builder.mutation<void, { guests: CommunityGuest[]; communityId: number | undefined }>({
      query: ({ communityId, guests }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `/community/${communityId}/members`,
          method: 'PUT',
          data: guests,
        };
      },
      invalidatesTags: ['ALL_COMMUNITY_MEMBERS'],
    }),
    removeCommunityMember: builder.mutation<
      void,
      { memberId: number; communityId: number | undefined; displayName: string }
    >({
      query: ({ communityId, memberId }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return {
          url: `/community/${communityId}/${memberId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ALL_COMMUNITY_MEMBERS'],
    }),
    getCurrentCommunityMember: builder.query<CommunityMember, { communityId: number | undefined }>({
      keepUnusedDataFor: 600,
      query: ({ communityId }) => {
        assert(communityId, ERROR_MESSAGES.noCommunityId);
        return `/community/${communityId}/me`;
      },
      providesTags: ['COMMUNITY_MEMBER'],
    }),
    updateCurrentCommunityMember: builder.mutation<
      void,
      {
        communityId: number;
        data: Partial<CommunityMemberFormData>;
        currentData: CommunityMember | undefined;
      }
    >({
      query: ({ communityId, data, currentData }) => ({
        url: `/community/${communityId}/members/me`,
        method: 'PATCH',
        data: mapMemberToApi(currentData, data),
      }),
      invalidatesTags: ['COMMUNITY_MEMBER'],
    }),
    updateMySocials: builder.mutation<void, { socials: Partial<CommunityMemberSocials>; communityId: number }>({
      query: ({ communityId, socials }) => ({
        url: `/community/${communityId}/members/me/socials`,
        method: 'PATCH',
        data: socials,
      }),
      invalidatesTags: ['COMMUNITY_MEMBER'],
    }),
    getCronofyAuthenticateUrl: builder.query<void, void>({
      query: () => ({
        url: '/cronofy/authenticate',
      }),
    }),
    submitCronofyCode: builder.mutation<void, { code: string }>({
      // TODO: uncomment below and remove code from listener.ts starting line 39 when BE fixed
      // invalidatesTags: ['COMMUNITY_MEMBER'],
      query: ({ code }) => ({
        url: '/cronofy/code',
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        data: code,
      }),
    }),
    registerToCommunity: builder.mutation<CommunityMember, { communityId: number }>({
      query: ({ communityId }) => ({
        url: `/community/${communityId}/invitation/register`,
        method: 'PATCH',
      }),
      invalidatesTags: ['MY_COMMUNITIES', 'COMMUNITY_MEMBER'],
    }),
    toggleCommunityAdmin: builder.mutation<void, CommunityAdminToggleRequest>({
      query: ({ accountId, isPromote, communityId }) => ({
        url: `/community/${communityId}/community-managers/${accountId}`,
        method: 'PATCH',
        params: {
          // TODO change to grantCommunityAdminRole after BE is ready
          grantCommunityManagerRole: isPromote,
        },
      }),
      invalidatesTags: (_result, _error, { accountId }) => [{ type: 'ALL_COMMUNITY_MEMBERS', id: accountId }],
    }),
    getAvailableTagsCategories: builder.query<TagsCategory[], void>({
      query: () => '/community/available-tag-categories',
    }),
    checkIfUserConnectedWith: builder.query<boolean, { communityId: number; connectionUserId?: number }>({
      keepUnusedDataFor: 0,
      query: ({ communityId, connectionUserId }) => {
        assert(connectionUserId, 'User ID required');
        return {
          url: `/community/${communityId}/members/connected-with`,
          params: {
            connectionUserId,
          },
        };
      },
    }),
    validateEventDate: builder.mutation<EventValidationResult, { date: Date; communityId: number }>({
      query: ({ communityId, date }) => ({
        url: `/community/${communityId}/validate-event`,
        method: 'POST',
        data: {
          startDate: date,
        },
      }),
    }),
    updateCommunityBranding: builder.mutation<number, UpdateCommunityBrandingPayload>({
      queryFn: async (data, { dispatch }) => {
        const { communityId, logo, defaultEventImage, deleteDefaultEventImageAttachmentId, ...payload } = data;
        assert(communityId, ERROR_MESSAGES.noCommunityId);

        if (defaultEventImage) {
          const attachment = await dispatch(
            attachmentsApi.endpoints.uploadAttachment.initiate(defaultEventImage)
          ).unwrap();
          payload.defaultEventImageAttachmentId = attachment.id;
        }

        // @ts-expect-error UpdateCommunityData type is deprecated
        await dispatch(communityApi.endpoints.updateMyCommunity.initiate({ id: communityId, ...payload })).unwrap();

        if (deleteDefaultEventImageAttachmentId) {
          const attachmentId = deleteDefaultEventImageAttachmentId;
          await dispatch(attachmentsApi.endpoints.deleteAttachment.initiate({ id: attachmentId })).unwrap();
        }

        if (logo) {
          await dispatch(communityApi.endpoints.uploadLogo.initiate({ communityId, logo })).unwrap();
        }

        return { data: communityId };
      },
    }),
    updateCommunitySettings: builder.mutation<void, { community: Community; settings: Partial<CommunitySettings> }>({
      queryFn: ({ community, settings }, { dispatch }) => {
        dispatch(
          communityApi.endpoints.updateMyCommunity.initiate({
            ...community,
            communitySettings: { ...community.communitySettings, ...settings },
          } as unknown as UpdateCommunityData)
        );

        return { data: undefined };
      },
    }),
    getCommunityAuthenticationMethods: builder.query<
      GetCommunityAuthenticationMethodsResponse,
      GetCommunityAuthenticationMethodsParams
    >({
      query: ({ slug }) => `/community/slug/${slug}`,
    }),
  }),
});

export const {
  useGetMyCommunitiesQuery,
  useLazyGetMyCommunitiesQuery,
  useGetCurrentCommunityMemberQuery,
  useUpdateCurrentCommunityMemberMutation,
  useLazyGetCronofyAuthenticateUrlQuery,
  useRegisterToCommunityMutation,
  useToggleCommunityAdminMutation,
  useGetCommunityMembersQuery,
  useRemoveCommunityMemberMutation,
  useInviteGuestsToCommunityMutation,
  useUpdateMyCommunityMutation,
  useUploadLogoMutation,
  useLazyGetCommunityMembersQuery,
  useUpdateMySocialsMutation,
  useCreateCommunityMutation,
  useGetAvailableTagsCategoriesQuery,
  useGetMyConnectionsQuery,
  useCheckIfUserConnectedWithQuery,
  useSubmitCronofyCodeMutation,
  useValidateEventDateMutation,
  useUpdateCommunityBrandingMutation,
  useUpdateCommunitySettingsMutation,
  useGetCommunityAuthenticationMethodsQuery,
} = communityApi;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { BroadcastWSAction, ChatWSAction, MeetingWSAction, RoomWSAction } from 'common/constants';
import { extractPathVariables, generateDynamicPath } from 'common/utils';
import { omit } from 'lodash';
import type { RootState } from 'store';
import { websocketSend } from 'store/features/socket/actions';

type MeetingWSActionPath = MeetingWSAction | BroadcastWSAction | ChatWSAction | RoomWSAction;

/**
 * Factory function for creating Redux async thunk for **Meetings** WebSocket actions.
 * @TODO Make it generic to handle other WS endpoints
 *
 * @template T - The type of data that will be sent with the WebSocket action.
 *
 * @param {MeetingWSAction} path - The path for the WebSocket action, which can contain variables indicated by a colon (e.g., '/meeting/:eventId/join').
 *
 * @param {T} data - The data to be sent with the WebSocket action.
 *
 * @example
 * ```ts
 * //before
 *
 * export const broadcastDemoteUser = createAsyncThunk(
 *   'event/broadcastDemoteUser',
 *   async ({ eventId, accountId }: { eventId: string; accountId: number }, { dispatch }) => {
 *     dispatch(
 *       websocketSend({
 *         path: `/meeting/${eventId}/broadcast/demote`,
 *         data: { accountId },
 *       })
 *     );
 *   }
 * );
 *
 * // after
 * export const broadcastDemoteUser_WS = createWSAsyncAction<MeetingWSParams<BroadcastDemoteUserParams>>('/meeting/:eventId/broadcast/demote');
 *
 * // usage in component or hook
 * const dispatch = useAppDispatch();
 * dispatch(broadcastDemoteUser_WS({ userId: accountId }));
 * ```
 *  */
export const createMeetingWSAsyncAction = <T extends Record<string, unknown>>(path: MeetingWSActionPath) =>
  createAsyncThunk<void, T>(path, (data, { dispatch, getState }) => {
    const { event } = getState() as RootState;
    if (!event.activeEventId) return;

    const payload = omit(data, extractPathVariables(path));
    dispatch(
      websocketSend({
        path: generateDynamicPath(path, { eventId: event.activeEventId, ...data }),
        data: Object.keys(payload).length ? payload : undefined,
        plainBody: true,
      })
    );
  });

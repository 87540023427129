/* eslint functional/no-let: 0 */
import { hslToRgb, rgbToHex } from '@mui/material';

import { assert } from './assert';

const BRIGHTNESS_THRESHOLD = 90;
const MAX_DOWNSIZED_IMAGE_WIDTH = 40;
const MAX_COLOR_VALUE = 255;

export const hexToHSL = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  assert(result, '[hexToHSL] Please provide color in hex format!');

  const r = parseInt(result[1], 16) / MAX_COLOR_VALUE;
  const g = parseInt(result[2], 16) / MAX_COLOR_VALUE;
  const b = parseInt(result[3], 16) / MAX_COLOR_VALUE;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  const noDiff = max === min;

  const luminescence = (max + min) / 2;

  const saturation = (() => {
    if (noDiff) {
      return 0;
    }
    if (luminescence > 0.5) {
      return diff / (2 - max - min);
    }
    return diff / (max + min);
  })();

  const hue = (() => {
    let hueValue = 0;

    if (noDiff) {
      return hueValue;
    }

    switch (max) {
      case r:
        hueValue = (g - b) / diff + (g < b ? 6 : 0);
        break;
      case g:
        hueValue = (b - r) / diff + 2;
        break;
      case b:
        hueValue = (r - g) / diff + 4;
        break;
    }
    return hueValue / 6;
  })();

  return initHSLObject({ hue, luminescence, saturation });
};

const initHSLObject = (initialValues: { hue: number; saturation: number; luminescence: number }) => {
  const values = { ...initialValues };

  const toString = () => `hsl(${values.hue * 360}, ${values.saturation * 100}%, ${values.luminescence * 100}%)`;

  const changeHue = (coefficient: number) => {
    values.hue = (values.hue + coefficient) % 1;
  };

  return {
    toString,
    changeHue,
  };
};

export const changeHue = (color: string, hueCoefficient: number) => {
  const hsl = hexToHSL(color);
  hsl.changeHue(hueCoefficient);
  return rgbToHex(hslToRgb(hsl.toString()));
};

const getScaledDimensions = (img: HTMLImageElement) => {
  let { width, height } = img;
  const aspectRatio = width / height;

  const maxHeight = MAX_DOWNSIZED_IMAGE_WIDTH / aspectRatio;

  if (width > height) {
    if (width > MAX_DOWNSIZED_IMAGE_WIDTH) {
      height = height * (MAX_DOWNSIZED_IMAGE_WIDTH / width);
      width = MAX_DOWNSIZED_IMAGE_WIDTH;
    }
  } else if (height > maxHeight) {
    width = width * (maxHeight / height);
    height = maxHeight;
  }
  return { width, height };
};

export const getImageBrightness = (
  imageSrc: string,
  callback: (args: { brightness: number; isBright: boolean }) => void
) => {
  const img = document.createElement('img');
  img.crossOrigin = 'Anonymous';
  // workaround for CORS error caused by cache on chromium based browsers: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
  img.src = `${imageSrc}?anonymous`;
  img.style.display = 'none';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const { width, height } = getScaledDimensions(img);
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.drawImage(img, 0, 0, width, height);

    const imageData = ctx.getImageData(0, 0, width, height);
    const { data } = imageData;

    // eslint-disable-next-line functional/no-let
    let red,
      green,
      blue,
      alpha,
      avgColor,
      avgColorSum = 0,
      pixels = 0;

    for (let x = 0, len = data.length; x < len; x += 4) {
      alpha = data[x + 3];
      if (alpha === 0) {
        // skip fully transparent pixels
        continue;
      }

      red = data[x];
      green = data[x + 1];
      blue = data[x + 2];

      const sum = red + green + blue;
      avgColor = MAX_COLOR_VALUE - ((MAX_COLOR_VALUE - Math.floor(sum / 3)) * alpha) / MAX_COLOR_VALUE;
      avgColorSum += avgColor;
      pixels++;
    }

    const brightness = (Math.floor(avgColorSum / pixels) * 100) / MAX_COLOR_VALUE;

    callback({
      brightness,
      isBright: brightness > BRIGHTNESS_THRESHOLD,
    });
  };
};

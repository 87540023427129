import React, { FC } from 'react';

import { FormControlLabelProps, type CheckboxProps as NatterCheckboxProps, SxProps, Theme } from '@mui/material';

import { StyledFormControlLabel, StyledFormControlLabelProps } from './Checkbox.styled';
import { CheckboxControl } from './CheckboxControl';

export type CheckboxProps = NatterCheckboxProps & {
  label?: React.ReactNode;
  labelSx?: SxProps<Theme>;
} & StyledFormControlLabelProps &
  Pick<FormControlLabelProps, 'labelPlacement'>;

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  label,
  labelSx,
  variant = 'default',
  labelPlacement,
  ...props
}) => (
  <StyledFormControlLabel
    sx={labelSx}
    control={<CheckboxControl {...props} />}
    // just in case someone passes empty string - it then makes it look broken
    label={label || undefined}
    labelPlacement={labelPlacement}
    variant={variant}
    checked={checked}
  />
);

import React from 'react';

import { ButtonMenu } from 'common/components/Buttons';
import { MoreHorizontalIcon } from 'icons';

// copy function so modifying its defaultProps doesn't affect ButtonMenu
export const TableCellMoreActions = ButtonMenu.bind({});

TableCellMoreActions.defaultProps = {
  Icon: <MoreHorizontalIcon />,
  TooltipProps: { placement: 'bottom-end' },
};

import { eventSlice } from './eventSlice';

export const {
  resetEventState,
  clearPinValue,
  addLastCallQuest,
  addAudience,
  addUserToAudience,
  demoteUserToAudience,
  removeUserFromAudience,
  setDisconnectedParticipants,
  clearStageInvitation,
  setCloudPlayer,
  setUserHandRaisedForUser,
  skipNattering,
  joinNattering,
  addRoom,
  removeRoom,
  addParticipantsWithRaisedHand,
  toggleShowAudienceList,
  setActivePanelTab,
  hideTranscriptionModal,
  updateRoom,
} = eventSlice.actions;

import { type Components, type Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    PopperProps: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  },
  styleOverrides: {
    tooltip: ({ theme: { palette, typography } }) => ({
      backgroundColor: palette.grey[900],
      color: palette.grey[200],
      ...typography['natter-text-xs'],
      fontWeight: 400,
      maxWidth: 300,

      '& .MuiTypography-root': {
        color: palette.grey[200],
      },

      '&&.MuiTooltip-tooltip': {
        margin: 0,
      },
    }),
    tooltipArrow: ({ theme: { palette } }) => ({
      '& .MuiTooltip-arrow': {
        color: palette.grey[900],
      },
    }),
  },
};

import {
  EventQuestionAssignmentType,
  EventQuestionDefinition,
  EventQuestionType as EventQuestionTypeV1,
} from 'domain/event';
import { cloneDeep, update } from 'lodash';
import { mapQuestionToApi, mapTopicQuestionToApi } from 'modules/question/mappers';
import type { EventWizardFormSerializedValues } from 'pages/EventWizard/validation/schema';

export const mapEventQuestionsFromApi = (
  data: EventQuestionDefinition[],
  withOrder = false
): EventQuestionDefinition[] => data.map((q, index) => mapEventQuestionFromApi({ withOrder })(q, index + 1));

// TODO To be moved to a Question module
export const mapEventQuestionFromApi =
  ({ withOrder = false } = {}) =>
  (data: EventQuestionDefinition, order?: number): EventQuestionDefinition =>
    update(cloneDeep(data), 'question.title', (title) => (withOrder ? `${order}. ${title}` : title));

export const mapAllEventWizardQuestionsToApi = ({
  nattersSetup,
  entrySurvey,
  exitSurvey,
}: Pick<EventWizardFormSerializedValues, 'entrySurvey' | 'exitSurvey' | 'nattersSetup'>) => [
  ...entrySurvey.questions.map((q) => mapQuestionToApi(q, EventQuestionAssignmentType.PreEvent)),
  ...exitSurvey.questions.map((q) => mapQuestionToApi(q, EventQuestionAssignmentType.EndOfEvent)),
  ...nattersSetup.topics.map((q) => mapTopicQuestionToApi(q, EventQuestionAssignmentType.Room)),
];

export const AssignmentTypeToQuestionType: Record<EventQuestionAssignmentType, EventQuestionTypeV1> = {
  [EventQuestionAssignmentType.PreEvent]: EventQuestionTypeV1.PreEvent,
  [EventQuestionAssignmentType.Room]: EventQuestionTypeV1.Icebreaker,
  [EventQuestionAssignmentType.EndOfRoom]: EventQuestionTypeV1.Feedback,
  [EventQuestionAssignmentType.EndOfEvent]: EventQuestionTypeV1.EndOfEvent,
};

import { set } from 'date-fns';
import { EventQuestionAssignmentType } from 'domain/event';
import { map, pick } from 'lodash';
import { mapQuestionToApi } from 'modules/question/mappers';
import { SurveyFormSchema } from 'pages/SurveyWizard/constants';
import { CreateSurveyPayload, CreateSurveyQuestionsPayload, CreateSurveyUsersPayload } from 'store/apis/survey';

export type SerializedSurveyFormData = Omit<CreateSurveyPayload, 'communityId'> &
  Omit<CreateSurveyQuestionsPayload, 'id'> &
  Omit<CreateSurveyUsersPayload, 'id'>;

export const mapSurveyFormToApi = (data: SurveyFormSchema): SerializedSurveyFormData => {
  const dueBy = set(data.dueBy, {
    hours: data.dueTime.getHours(),
    minutes: data.dueTime.getMinutes(),
    seconds: data.dueTime.getSeconds(),
    milliseconds: data.dueTime.getMilliseconds(),
  });

  const reminderIntervalAmount = data.reminderDisabled ? 0 : data.reminderIntervalAmount;

  return {
    ...pick(data, ['welcomeMessage', 'reminderIntervalTimeUnit']),

    name: data.name!,
    dueBy: dueBy.toISOString(),
    reminderIntervalAmount,
    emails: map(data.users, 'email'),
    questions: data.questions.map((q) =>
      // Async surveys does not require question assignment type
      // TODO mapQuestionToApi should be generic for all questions (to not use from the event mappers)
      mapQuestionToApi(q, undefined as unknown as EventQuestionAssignmentType)
    ),
  };
};

import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';

import { Alert, Grid } from '@mui/material';
import { TFunction } from 'i18next';

import { Button } from '../_legacy/Button';
import { ErrorBoundaryContext } from './ErrorBoundaryContext';

type ErrorBoundaryProps = PropsWithChildren<{
  fallback?: ReactNode;
  onRetry?(): void;
  hasError?: boolean;
  message?: string;
  onError?(): void;
  t: TFunction;
}>;

interface State {
  hasError: boolean;
  message: string;
}

const defaultMessage = 'Something went wrong';

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
    message: defaultMessage,
  };

  static getDerivedStateFromError(): State {
    return { hasError: true, message: defaultMessage };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(
      `Uncaught error: ${JSON.stringify({
        errorMessage: error.message,
        errorInfo,
      })}`
    );
    this.props.onError?.();
  }

  triggerError = ({ message, error, errorInfo }: { message?: string; error?: Error; errorInfo?: ErrorInfo }): void => {
    console.error(
      `Uncaught error: ${JSON.stringify({
        errorMessage: error?.message,
        errorInfo,
        message,
      })}`
    );
    this.setState({ hasError: true, message: message ?? defaultMessage });
  };

  retry = (): void => {
    this.setState({ hasError: false });
    this.props.onRetry?.();
  };

  render(): ReactNode {
    const hasError = this.props.hasError || this.state.hasError;
    const message = this.props.hasError && this.props.message ? this.props.message : this.state.message;
    const { fallback } = this.props;

    return (
      <ErrorBoundaryContext.Provider
        value={{
          triggerError: this.triggerError,
        }}
      >
        {hasError
          ? (fallback ?? (
              <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%', width: '100%' }}
              >
                <Grid item>
                  <Alert severity="error">{message}</Alert>
                </Grid>
                {!!this.props.onRetry && (
                  <Grid item>
                    <Button variant="contained" onClick={this.props.onRetry}>
                      {this.props.t('retry')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))
          : this.props.children}
      </ErrorBoundaryContext.Provider>
    );
  }
}

import React, { FC } from 'react';

import {
  Box,
  Checkbox as NatterCheckbox,
  type CheckboxProps as NatterCheckboxProps,
  Stack,
  alpha,
} from '@mui/material';

const rippleStyles = {
  width: 40,
  height: 40,
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
};

export const CheckboxControl: FC<NatterCheckboxProps> = ({ ...props }) => (
  <Stack
    sx={{
      position: 'relative',
      width: 24,
      height: 24,
      '&:hover, &:focus': ({ palette }) => ({
        '& > .MuiBox-root': {
          backgroundColor: alpha(palette.primary.main, 0.05),
        },
      }),
    }}
  >
    <Box
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        transition: 'background-color 0.1s',
        borderRadius: '50%',
        ...rippleStyles,
      }}
    />
    <NatterCheckbox
      {...props}
      sx={{
        '& .MuiTouchRipple-root': rippleStyles,
      }}
    />
  </Stack>
);

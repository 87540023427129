import React, { ComponentType, FC, MouseEvent, MouseEventHandler, useState } from 'react';

import { Box, ClickAwayListener, Popover, PopoverOrigin, SxProps, Theme } from '@mui/material';

export type WithPopoverProps = {
  onClick: MouseEventHandler<HTMLElement>;
  isActive: boolean;
};

type Config = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
  contentSx?: SxProps;
};

export const withPopover =
  <P extends object>(
    WrappedComponent: ComponentType<P & WithPopoverProps>,
    config?: Config
  ): FC<Omit<P, 'onClick' | 'isActive'>> =>
  ({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
      <>
        <WrappedComponent {...(props as P)} onClick={handleOpen} isActive={!!anchorEl} />
        <Popover
          sx={config?.sx ?? { mt: 1 }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={
            config?.anchorOrigin ?? {
              vertical: 'bottom',
              horizontal: 'right',
            }
          }
          transformOrigin={
            config?.transformOrigin ?? {
              vertical: 'top',
              horizontal: 'right',
            }
          }
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Box p={2} sx={config?.contentSx}>
              {children}
            </Box>
          </ClickAwayListener>
        </Popover>
      </>
    );
  };

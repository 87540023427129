import React, { ComponentProps, FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';
import { DescriptionWithExplanation } from 'common/components';

type SectionHeaderProps = {
  title: ReactNode;
  description?: ReactNode;
  variant?: ComponentProps<typeof Typography>['variant'];
  explanation?: string;
  Action?: ReactNode;
  required?: boolean;
} & Partial<Pick<HTMLLabelElement, 'htmlFor'>>;

export const SectionHeader: FC<SectionHeaderProps> = ({
  variant = 'natter-text-sm',
  title,
  description,
  explanation,
  Action,
  required,
  htmlFor,
}) => (
  <Stack component={htmlFor ? 'label' : 'div'} gap={1} htmlFor={htmlFor}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={0.3}>
        <Typography
          variant={variant}
          fontWeight={600}
          color="text.primary"
          component="h2"
          data-testid="section-title"
          sx={{ wordBreak: 'break-word' }}
        >
          {title}
          {required && (
            <Typography variant={variant} component="span" color="error">
              *
            </Typography>
          )}
        </Typography>
      </Stack>
      {Action}
    </Stack>
    {description && <DescriptionWithExplanation description={description} explanation={explanation} />}
  </Stack>
);

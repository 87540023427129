import { ElementType } from 'react';

import { Typography, TypographyProps, styled } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';

import { TextField } from '../TextField';

export const InlineEditorInactive = styled(Typography, {
  name: 'InlineEditorWrapper',
})<TypographyProps & { component: ElementType; disabled: boolean }>(({ theme: { spacing, palette }, disabled }) => ({
  width: '100%',
  border: '1px solid transparent',
  borderRadius: spacing(0.5),
  fontWeight: 'inherit',
  padding: spacing(0.125, 1),
  cursor: disabled ? undefined : 'pointer',
  whiteSpace: 'pre',
  ...textEllipsis,

  '&:hover': {
    borderColor: disabled ? undefined : palette.text.primary,
  },
}));

export const InlineEditorActive = styled(TextField, { shouldForwardProp: (prop) => prop !== 'typographyVariant' })<{
  typographyVariant: TypographyProps['variant'];
}>(({ theme: { spacing, typography }, typographyVariant = 'inherit' }) => ({
  flex: 1,
  fontWeight: 'inherit',
  lineHeight: 'inherit',

  '& .MuiInputBase-root': {
    padding: 0,
    fontWeight: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',

    ...(typography[typographyVariant] && {
      ...typography[typographyVariant],
      minHeight: 0,
    }),

    '& input, & textarea': {
      padding: spacing(0.25, 1.125),
      height: 'unset',
    },
  },
}));

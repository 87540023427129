import React, { ReactNode, forwardRef } from 'react';

import { Box, Breakpoint, StackProps } from '@mui/material';
import { isNumber } from 'lodash';

import { SidebarWrapper, Wrapper } from './CenteredWithSidebar.styled';

const DEFAULT_SIDEBAR_WIDTH = 400;
const DEFAULT_CONTENT_WIDTH = 720;

type Props = {
  sidebarWidth?: number | string;
  sidebar?: ReactNode;
  maxWidth?: number | Breakpoint;
} & Omit<StackProps, 'ref'>;

export const CenteredWithSidebar = forwardRef<HTMLDivElement, Props>(
  ({ sidebar, sidebarWidth = DEFAULT_SIDEBAR_WIDTH, maxWidth = DEFAULT_CONTENT_WIDTH, children, ...props }, ref) => (
    <Box overflow="auto" width="100%" ref={ref}>
      <Wrapper {...props}>
        <SidebarWrapper width={sidebarWidth}>
          <Box>{sidebar}</Box>
        </SidebarWrapper>

        <Box component="main" width="100%" maxWidth={maxWidth}>
          {children}
        </Box>
        <Box flexGrow={1} flexShrink={0} sx={{ maxWidth: isNumber(sidebarWidth) ? sidebarWidth + 32 : undefined }} />
      </Wrapper>
    </Box>
  )
);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ReactionsState } from './types';

const initialState: ReactionsState = {
  reactions: {},
};

export const reactionsSlice = createSlice({
  name: 'reactions',
  initialState,
  reducers: {
    resetReactionsState: () => initialState,
    setReactions: (state, { payload: { reaction, count } }: PayloadAction<{ reaction: string; count: number }>) => {
      state.reactions = { [reaction]: count };
    },
  },
});

export const { resetReactionsState, setReactions } = reactionsSlice.actions;

import React, { FC, Ref, SVGProps, forwardRef, memo } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';
import { isProduction } from 'common/utils';

/**
 * Private module reserved for @mui packages.
 * Based on https://github.com/mui/material-ui/blob/master/packages/mui-material/src/utils/createSvgIcon.js
 */
export const createSvgIcon = (component: FC<SVGProps<SVGSVGElement>>, displayName: string) => {
  const Component = <T,>(props: SvgIconProps, ref: Ref<T>) => (
    <SvgIcon
      component={component}
      data-testid={`${displayName}Icon`}
      ref={ref}
      inheritViewBox
      sx={{ fill: 'transparent' }}
      fontSize="inherit"
      {...props}
    />
  );

  if (!isProduction()) {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return memo(forwardRef(Component));
};

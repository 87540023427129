import React, { BaseSyntheticEvent, FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Popover, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/_legacy/Button';
import { TagsSelect } from 'common/components/_legacy/Form';
import { mapTagsFromCategories } from 'community/mappers';
import type { Tag, TagsCategory } from 'domain/Common';

import { useFilterByTagsModalStyles } from './FilterByTagsModal.styles';

export const FilterByTagsModal: FC<{
  initialSelectedTags: Tag[];
  onClose: () => void;
  anchor: Element | null;
  onChange(tags: Tag[]): void;
  tagsCategories: TagsCategory[];
}> = ({ tagsCategories, initialSelectedTags, onClose, anchor, onChange }) => {
  const classes = useFilterByTagsModalStyles();
  const selectedTagsCategories = useMemo<TagsCategory[]>(
    () =>
      tagsCategories.map((c) => ({
        ...c,
        tags: c.tags.map((tag) => ({
          ...tag,
          isSelected: initialSelectedTags.some((t) => t.id === tag.id),
        })),
      })),
    [tagsCategories, initialSelectedTags]
  );

  const methods = useForm({
    defaultValues: {
      tagsCategories: selectedTagsCategories,
    },
  });

  useEffect(() => {
    if (anchor) {
      methods.setValue('tagsCategories', selectedTagsCategories);
    }
  }, [anchor, methods, selectedTagsCategories]);

  const handleConfirm = methods.handleSubmit((data) => {
    onChange(mapTagsFromCategories(data.tagsCategories).filter((t) => t && t.isSelected));
    onClose();
  });

  const handleCancel = () => {
    methods.setValue('tagsCategories', selectedTagsCategories);
    onClose();
  };

  const handleClearSelectedTags = () => methods.setValue('tagsCategories', tagsCategories);

  const handleClose = (event: BaseSyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return handleConfirm(event);
    }
    onClose();
  };

  return (
    <Popover
      slotProps={{ paper: { className: classes.filterPopover } }}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      open={!!anchor}
      anchorEl={anchor}
      sx={{ marginTop: 2 }}
    >
      <Box>
        <FormProvider {...methods}>
          <TagsSelect name="tagsCategories" unselectSingleEnabled />
        </FormProvider>
      </Box>
      <Stack flexDirection="row" gap={1} mt={4}>
        <Typography
          onClick={handleClearSelectedTags}
          variant="caption"
          display="flex"
          alignItems="center"
          className={classes.clearAll}
          sx={{ flex: 1 }}
        >
          Clear all
        </Typography>

        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Popover>
  );
};

import { Chip, Palette, styled } from '@mui/material';

export const MemberTagItem = styled(Chip, { name: 'MemberTagItem' })(({ theme: { spacing, palette }, variant }) => ({
  height: 'initial',
  color: palette.grey[600],
  borderColor: palette.grey[400],
  borderRadius: spacing(0.5),
  fontWeight: 500,
  lineHeight: '20px',
  padding: spacing(0.5, 1),

  '& .MuiChip-label': {
    padding: 0,
  },

  '&:hover, &.MuiChip-clickable:hover': getFilledStyle(palette),

  ...(variant === 'filled' && getFilledStyle(palette)),
}));

const getFilledStyle = (palette: Palette) => ({
  border: '1px solid',
  borderColor: palette.primary.main,
  color: palette.primary.main,
  backgroundColor: palette.extendedPrimary[25],
});

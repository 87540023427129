import { createSelector } from '@reduxjs/toolkit';
import { pipe, prop } from 'lodash/fp';
import type { RootState } from 'store';

import { EventWizardMode, EventWizardState, ProgressTrackerState } from './types';

const selectEventWizardState: (state: RootState) => RootState['eventWizard'] = prop('eventWizard');

const selectProgressTrackerState = pipe<RootState[], EventWizardState, ProgressTrackerState>(
  selectEventWizardState,
  prop('progressTracker')
);

export const selectEventWizardActiveStep = pipe<RootState[], ProgressTrackerState, ProgressTrackerState['activeStep']>(
  selectProgressTrackerState,
  prop('activeStep')
);

export const selectEventWizardSteps = pipe<RootState[], ProgressTrackerState, ProgressTrackerState['steps']>(
  selectProgressTrackerState,
  prop('steps')
);

export const selectEventWizardNextStep = createSelector(
  [selectEventWizardActiveStep, selectEventWizardSteps],
  (activeStep, steps) => (activeStep < steps.length ? steps[activeStep + 1] : undefined)
);

export const selectEventWizardIsLoading = pipe<RootState[], EventWizardState, EventWizardState['isLoading']>(
  selectEventWizardState,
  prop('isLoading')
);

const selectEventWizardForm = pipe<RootState[], EventWizardState, EventWizardState['form']>(
  selectEventWizardState,
  prop('form')
);

export const selectEventWizardFormMode = pipe<RootState[], EventWizardState['form'], EventWizardMode | undefined>(
  selectEventWizardForm,
  prop('mode')
);

export const selectEventWizardFormIsDirty = pipe<RootState[], EventWizardState['form'], boolean>(
  selectEventWizardForm,
  prop('isDirty')
);

export const selectEventWizardFormIsOnceFilled = pipe<RootState[], EventWizardState['form'], boolean>(
  selectEventWizardForm,
  prop('requiredFieldsOnceFilled')
);

export const selectEditEventId = pipe<RootState[], EventWizardState, EventWizardState['eventId']>(
  selectEventWizardState,
  prop('eventId')
);

export const selectIsEditMode = createSelector(selectEventWizardFormMode, (mode) => mode === 'edit');

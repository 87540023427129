import React, { FC } from 'react';

import { Alert, type AlertProps } from '@mui/material';

type BannerProps = Pick<AlertProps, 'children' | 'severity' | 'sx'> & {
  showIcon?: boolean;
  sx?: AlertProps['sx'];
  fixed?: boolean;
};

export const Banner: FC<BannerProps> = ({ showIcon = true, fixed, ...alertProps }) => (
  <Alert
    icon={showIcon === false ? showIcon : undefined}
    sx={
      fixed
        ? ({ zIndex }) => ({
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: zIndex.snackbar,
          })
        : undefined
    }
    {...alertProps}
  />
);

import { createApi } from '@reduxjs/toolkit/query/react';
import { serializeDateFields } from 'common/utils/time';
import { PaginatedApiPayloadV2 } from 'domain/Common';
import {
  EventDetails,
  EventGuest,
  EventImageApiResponse,
  EventListItem,
  EventQuestionDefinition,
  ListedEventGuest,
  PublicEventDetails,
} from 'domain/event';
import {
  mapConnectionsFromApi,
  mapEventDetailsFromApi,
  mapEventQuestionsFromApi,
  mapEventWizardFormValuesToApi,
  mapGetEventGuestsParams,
  mapPaginatedEventGuestsFromApi,
  mapPaginatedEventListItemsFromApi,
  mapPublicEventFromApi,
} from 'event/mappers';
import { flow } from 'lodash/fp';
import { getApiBaseUrl } from 'modules/api/utils';
import { generateFormData } from 'store/utils';

import axiosBaseQuery from '../_axiosBaseQuery';
import type {
  CreateEventPayload,
  CreateEventQuestionsPayload,
  CreatedEventApiResponse,
  GetEventGuestsPayload,
  GetEventQuestionsPayload,
  GetEventsListPayload,
  StartEventPayload,
  UpdateEventAnswersPayload,
  UpdateEventImagePayload,
  UpdateEventPayload,
  ValidateEventPinPayload,
  WithEventId,
} from './types';
import { omitUndefined } from './utils';

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: axiosBaseQuery({ baseURL: getApiBaseUrl('v2') }),
  tagTypes: ['EVENT', 'ALL_EVENTS', 'PAST_EVENTS', 'UPCOMING_EVENT', 'EVENT_QUESTIONS'],
  endpoints: (builder) => ({
    getEventsList: builder.query<PaginatedApiPayloadV2<EventListItem>, GetEventsListPayload>({
      providesTags: ['ALL_EVENTS'],
      query: ({ page, pageSize, filters }) => ({
        url: `/event`,
        params: {
          page,
          size: pageSize,
          states: filters?.states?.join(','),
          communityId: filters?.communityId,
          searchText: filters?.searchText,
        },
      }),
      transformResponse: mapPaginatedEventListItemsFromApi,
    }),
    getEventDetails: builder.query<EventDetails, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}`,
      }),
      transformResponse: flow(serializeDateFields('startDate'), mapEventDetailsFromApi),
      providesTags: (result) => (result ? [{ type: 'EVENT', id: result.id }] : []),
    }),
    getPublicEventDetails: builder.query<PublicEventDetails, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/register`,
      }),
      transformResponse: flow(serializeDateFields('startTime'), mapPublicEventFromApi),
    }),
    getEventGuests: builder.query<PaginatedApiPayloadV2<EventGuest>, GetEventGuestsPayload>({
      query: ({ id, ...args }) => ({
        url: `/event/${id}/guests`,
        params: mapGetEventGuestsParams(args),
      }),
      transformResponse: mapPaginatedEventGuestsFromApi,
    }),
    getEventGuestsCount: builder.query<number, { id: number | undefined }>({
      query: ({ id }) => ({
        url: `/event/${id}/guests`,
      }),
      transformResponse: (data: PaginatedApiPayloadV2<ListedEventGuest>) => data.totalCount,
    }),
    createEvent: builder.mutation<CreatedEventApiResponse, CreateEventPayload>({
      query: (data) => ({
        url: '/event',
        method: 'POST',
        data: mapEventWizardFormValuesToApi(data),
      }),
      invalidatesTags: ['ALL_EVENTS', 'UPCOMING_EVENT'],
    }),
    updateEvent: builder.mutation<void, UpdateEventPayload>({
      query: ({ id, ...data }) => ({
        url: `/event/${id}`,
        method: 'PUT',
        data: mapEventWizardFormValuesToApi(data),
      }),
      invalidatesTags: ['ALL_EVENTS'],
    }),
    getEventQuestions: builder.query<EventQuestionDefinition[], GetEventQuestionsPayload>({
      query: ({ id, assignmentType }) => ({
        url: `/event/${id}/questions`,
        params: { assignmentType },
      }),
      transformResponse: mapEventQuestionsFromApi,
      providesTags: ['EVENT_QUESTIONS'],
    }),
    createEventQuestions: builder.mutation<void, CreateEventQuestionsPayload>({
      query: ({ id, questions }) => ({
        url: `/event/${id}/questions`,
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: ['EVENT_QUESTIONS'],
    }),
    createEventMeetingQuestions: builder.mutation<void, CreateEventQuestionsPayload>({
      query: ({ id, questions }) => ({
        url: `/event/${id}/meeting-questions`,
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: ['EVENT_QUESTIONS'],
    }),
    updateEventAnswers: builder.mutation<void, UpdateEventAnswersPayload>({
      query: ({ id, answers, type }) => ({
        url: `/event/${id}/answers/${type}`,
        method: 'POST',
        data: answers,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    deleteEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'UPCOMING_EVENT', { type: 'EVENT', id }],
    }),
    registerForEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/event/${id}/register`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    startEvent: builder.mutation<void, StartEventPayload>({
      query: ({ id, ...params }) => ({
        url: `/meeting/${id}/start`,
        method: 'POST',
        data: params,
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'UPCOMING_EVENT', { type: 'EVENT', id }],
    }),
    stopEvent: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/meeting/${id}/stop`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { id }) => ['ALL_EVENTS', 'PAST_EVENTS', { type: 'EVENT', id }],
    }),
    startBroadcast: builder.mutation<void, WithEventId>({
      query: ({ id }) => ({
        url: `/meeting/${id}/start?withBroadcast=1`,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'EVENT', id }],
    }),
    postEventImage: builder.query<EventImageApiResponse, UpdateEventImagePayload>({
      forceRefetch: () => true,
      query: ({ id, ...params }) => ({
        url: `/event/${id}/images`,
        headers: { 'Content-Type': 'multipart/form-data' },
        method: 'POST',
        data: generateFormData(omitUndefined(params)),
      }),
    }),
    getConnections: builder.query<EventGuest[], WithEventId>({
      query: ({ id }) => `/meeting/${id}/myConnection`,
      transformResponse: mapConnectionsFromApi,
    }),
    validateEventPin: builder.query<void, ValidateEventPinPayload>({
      query: ({ id, pin }) => ({ url: `/event/${id}/validate-pin`, params: { pin } }),
    }),
    getTroubleshootingToken: builder.query<{ agoraToken: string; channelName: string }, void>({
      keepUnusedDataFor: 0,
      query: () => `/meeting/connectionTestToken`,
    }),
  }),
});

export const {
  useCreateEventMutation,
  useCreateEventQuestionsMutation,
  useCreateEventMeetingQuestionsMutation,
  useDeleteEventMutation,
  useGetConnectionsQuery,
  useGetEventDetailsQuery,
  useGetEventGuestsQuery,
  useGetEventGuestsCountQuery,
  useGetEventQuestionsQuery,
  useGetEventsListQuery,
  useGetTroubleshootingTokenQuery,
  useLazyGetEventQuestionsQuery,
  useGetPublicEventDetailsQuery,
  useLazyValidateEventPinQuery,
  useRegisterForEventMutation,
  useStartBroadcastMutation,
  useStartEventMutation,
  useStopEventMutation,
  useUpdateEventAnswersMutation,
  useUpdateEventMutation,
} = eventApi;

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { MediaTrackState } from 'domain/Broadcast';

import { mediaSettingsActions } from './mediaSettings.actions';
import { MediaSettingsState } from './types';

const initialState: MediaSettingsState = {
  cameraTrack: {
    error: null,
    state: MediaTrackState.NotReady,
  },
  microphoneTrack: {
    error: null,
    state: MediaTrackState.NotReady,
  },
};

export const mediaSettingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(mediaSettingsActions.cameraTrack.creation.started, (state) => {
      state.cameraTrack.error = null;
      state.cameraTrack.state = MediaTrackState.Enabling;
    })
    .addCase(mediaSettingsActions.cameraTrack.creation.completed, (state) => {
      state.cameraTrack.state = MediaTrackState.Enabled;
    })
    .addCase(mediaSettingsActions.cameraTrack.creation.failed, (state, { payload }) => {
      state.cameraTrack.error = payload;
      state.cameraTrack.state = MediaTrackState.NotReady;
    })
    .addCase(mediaSettingsActions.microphoneTrack.creation.started, (state) => {
      state.microphoneTrack.error = null;
      state.microphoneTrack.state = MediaTrackState.Enabling;
    })
    .addCase(mediaSettingsActions.microphoneTrack.creation.completed, (state) => {
      state.microphoneTrack.state = MediaTrackState.Enabled;
    })
    .addCase(mediaSettingsActions.microphoneTrack.creation.failed, (state, { payload }) => {
      state.microphoneTrack.error = payload;
      state.microphoneTrack.state = MediaTrackState.NotReady;
    })
    .addMatcher(
      isAnyOf(mediaSettingsActions.cameraTrack.cleanedUp, mediaSettingsActions.microphoneTrack.cleanedUp),
      () => initialState
    );
});

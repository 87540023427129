import { createAction } from '@reduxjs/toolkit';
import { MediaTrackErrorKey } from 'devices';

export const mediaSettingsActions = {
  cameraTrack: {
    cleanedUp: createAction('[Media Settings] camera track cleaned up'),
    creation: {
      started: createAction('[Media Settings] camera track creation started'),
      completed: createAction('[Media Settings] camera track creation completed'),
      failed: createAction<MediaTrackErrorKey>('[Media settings] camera track creation failed'),
    },
  },
  microphoneTrack: {
    cleanedUp: createAction('[Media Settings] microphone track cleaned up'),
    creation: {
      started: createAction('[Media Settings] microphone track creation started'),
      completed: createAction('[Media Settings] microphone track creation completed'),
      failed: createAction<MediaTrackErrorKey>('[Media Settings] microphone track creation failed'),
    },
  },
};

import { styled } from '@mui/material';
import { shadows } from 'common/theme';
import { customColors } from 'common/theme/palette';

export const SnackbarWrapper = styled('div')<{ isDarkTheme: boolean }>(
  ({ theme: { breakpoints, palette, spacing, typography }, isDarkTheme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),

    ...typography['natter-text-sm'],
    textAlign: 'center',

    backgroundColor: isDarkTheme ? customColors.darkModeNotificationBackground : palette.common.black,
    boxShadow: isDarkTheme ? shadows.medium : undefined,
    color: palette.common.white,
    padding: spacing(1.5, 2),
    borderRadius: '8px',
    transition: 'width 0.5s linear',

    whiteSpace: 'pre-wrap',
    pointerEvents: 'all',

    [breakpoints.up('sm')]: {
      minWidth: 'initial',
    },

    [breakpoints.down('sm')]: {
      backgroundColor: isDarkTheme ? palette.common.white : palette.common.black,
      color: isDarkTheme ? palette.common.black : palette.common.white,
    },
  })
);

SnackbarWrapper.defaultProps = {
  role: 'alert',
};

import React, { FC } from 'react';

import { Avatar } from '@mui/material';

import { ImageBackground } from '../ImageBackground';
import { ImageDropzone, ImageDropzoneProps } from '../ImageDropzone';
import { UploadPreview, type UploadPreviewProps } from '../UploadPreview';
import { ImageFile } from './types';

export type ImageDropzoneWithPreviewProps = ImageDropzoneProps & {
  image?: ImageFile;
  onRemoveClick: UploadPreviewProps['onRemoveClick'];
};

export const ImageDropzoneWithPreview: FC<ImageDropzoneWithPreviewProps> = ({
  image,
  onRemoveClick: handleRemoveClick,
  disabled,
  ...imageDropzoneProps
}) => {
  if (image?.data) {
    return (
      <UploadPreview name={image.name} size={image.size} onRemoveClick={handleRemoveClick} disabled={disabled}>
        <ImageBackground src={image.data}>
          <Avatar src={image.data} alt={image.name} sx={{ '& .MuiAvatar-img': { objectFit: 'contain' } }} />
        </ImageBackground>
      </UploadPreview>
    );
  }

  if (image?.status === 'compressing') {
    return <UploadPreview name={image.name} size={image.size} status="compressing" progress={image.progress} />;
  }

  return <ImageDropzone {...imageDropzoneProps} disabled={disabled} />;
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TooltipProps } from '@mui/material';
import { Tooltip } from 'common/components/Tooltip';
import { grey, orange } from 'common/theme/palette';
import { typography } from 'common/theme/typography';

type Props = {
  isMicrophoneIssueDetected: boolean;
  children: TooltipProps['children'];
};

export const MicrophoneWarningTooltip: FC<Props> = ({ isMicrophoneIssueDetected, children }) => {
  const { t } = useTranslation('devices');

  return (
    <Tooltip
      title={t('microphoneMuted')}
      open={isMicrophoneIssueDetected}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            ...typography['natter-text-sm'],
            backgroundColor: orange[400],
            color: grey[800],
            textAlign: 'center',
            borderColor: orange[400],
          },
        },
        arrow: {
          sx: {
            '&::before': { backgroundColor: orange[400], borderColor: orange[400] },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

import React, { FC, MouseEvent, MouseEventHandler } from 'react';

import { Typography } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';
import { CloseIcon } from 'icons';

import {
  FilterBaseArrowIcon,
  FilterBaseButtonContent,
  FilterBaseButtonWrapper,
  FilterBaseClearButton,
} from './FilterBase.styled';

type Props = {
  isOpen: boolean;
  buttonLabel: string;
  isValueSelected: boolean;
  fullWidth?: boolean;
  onOpen(event: MouseEvent<HTMLButtonElement>): void;
  onReset(): void;
  'data-testid'?: string;
};

export const FilterBaseButton: FC<Props> = ({
  isOpen,
  buttonLabel,
  isValueSelected,
  fullWidth = false,
  onReset,
  onOpen,
  'data-testid': dataTestId,
}) => {
  const preventPropagation = (e: { stopPropagation: Function }) => e.stopPropagation();

  const handleClearIconClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    preventPropagation(e);
    onReset();
  };

  return (
    <>
      <FilterBaseButtonWrapper
        onClick={onOpen}
        isValueSelected={isValueSelected}
        isOpen={isOpen}
        fullWidth={fullWidth}
        data-testid={dataTestId}
      >
        <FilterBaseButtonContent>
          <Typography sx={{ ...textEllipsis }} title={buttonLabel} variant="natter-text-sm">
            {buttonLabel}
          </Typography>
          {isValueSelected ? (
            <FilterBaseClearButton
              onClick={handleClearIconClick}
              onMouseDown={preventPropagation}
              data-testid="FilterBaseButton-ClearButton"
            >
              <CloseIcon fontSize="small" color="inherit" />
            </FilterBaseClearButton>
          ) : (
            <FilterBaseArrowIcon isOpen={isOpen} />
          )}
        </FilterBaseButtonContent>
      </FilterBaseButtonWrapper>
    </>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const selectAllMediaState = (state: RootState) => state.mediaSettings;

const selectCameraTrack = createSelector(selectAllMediaState, (mediaState) => mediaState.cameraTrack);
export const selectCameraTrackState = createSelector(selectCameraTrack, (cameraTrack) => cameraTrack.state);
export const selectCameraTrackError = createSelector(selectCameraTrack, (cameraTrack) => cameraTrack.error);

const selectMicrophoneTrack = createSelector(selectAllMediaState, (mediaState) => mediaState.microphoneTrack);
export const selectMicrophoneTrackState = createSelector(
  selectMicrophoneTrack,
  (microphoneTrack) => microphoneTrack.state
);
export const selectMicrophoneTrackError = createSelector(
  selectMicrophoneTrack,
  (microphoneTrack) => microphoneTrack.error
);

export const selectShouldDisableBlurToggle = createSelector(selectCameraTrackError, (error) => !!error);

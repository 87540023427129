import React, { FC } from 'react';

import { PopperPlacementType } from '@mui/material';

import { FilterBaseButton } from './FilterBaseButton';
import { FilterBasePopper } from './FilterBasePopper';
import { useFilterBase } from './useFilterBase';

type Props = {
  label: string;
  buttonLabel: string;
  isValueSelected: boolean;
  fullWidth?: boolean;
  placement?: PopperPlacementType;
  onApply(): void;
  onOpen(): void;
  onReset(): void;
  'data-testid'?: string;
};

export const FilterBase: FC<Props> = ({
  label,
  buttonLabel,
  isValueSelected,
  fullWidth = false,
  placement,
  children,
  onApply,
  onReset,
  onOpen,
  'data-testid': dataTestId,
}) => {
  const { isOpen, anchorEl, openPopper, closePopper, handleReset, handleApplyButton } = useFilterBase({
    onApply,
    onOpen,
    onReset,
  });

  return (
    <>
      <FilterBaseButton
        isOpen={isOpen}
        buttonLabel={buttonLabel}
        isValueSelected={isValueSelected}
        onOpen={openPopper}
        onReset={handleReset}
        fullWidth={fullWidth}
        data-testid={dataTestId}
      />

      <FilterBasePopper
        label={label}
        anchorEl={anchorEl}
        onApply={handleApplyButton}
        onClose={closePopper}
        onReset={handleReset}
        placement={placement}
      >
        {children}
      </FilterBasePopper>
    </>
  );
};

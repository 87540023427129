import { EventHostType, EventRoom } from 'domain/event';

import type { AlertData } from '../alerts';
import type { EventState } from './types';

export const getActiveRoom = (state: EventState) => findRoomById(state.rooms, state.activeRoom?.id);

export const findRoomById = (rooms: EventRoom[], roomId: string | null | undefined) =>
  roomId ? rooms.find((r) => r.id === roomId) : undefined;

export const findAttendeeById = (room: EventRoom, attendeeId: number) =>
  room.attendees.find((a) => a.id === attendeeId);

export const getNotificationRecentSpeakersCount = (previousNotification: AlertData | undefined) => {
  if (typeof previousNotification?.message === 'object' && 'metadata' in previousNotification.message) {
    const previousRecentSpeakersCount = (previousNotification.message.metadata as number | undefined) ?? 0;
    return previousRecentSpeakersCount + 1;
  }
  return 1;
};

export const isHost = (hosts: EventHostType[] | undefined, attendeeId: number | undefined) =>
  !!(attendeeId && hosts?.some(({ accountId }) => accountId === attendeeId));

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { assert, generateDynamicPath } from 'common/utils';
import { pick } from 'lodash';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { RootState } from 'store';
import { ReportSource } from 'store/apis/insightsReport';
import { AsyncSurvey, selectCurrentSurvey, selectIsCurrentQuestionsReadOnly, surveyApi } from 'store/apis/survey';
import { addAppAlertDialog, addAppSuccessMessage } from 'store/features/alerts';
import { selectActiveCommunityId } from 'store/features/community';

import { SetupSurveyProgressTrackerStep } from '../components/SetupSurveyStep/constants';
import { SerializedSurveyFormData } from '../components/SetupSurveyStep/utils';

export const publishSurvey = createAsyncThunk<void, SerializedSurveyFormData>(
  '[SurveyWizard] publish initialized',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const communityId = selectActiveCommunityId(state);
    assert(communityId);

    const createFields: Array<keyof SerializedSurveyFormData> = [
      'name',
      'dueBy',
      'reminderIntervalAmount',
      'reminderIntervalTimeUnit',
      'welcomeMessage',
    ];

    const createValues = pick(payload, createFields);

    try {
      const { id } = await dispatch(
        surveyApi.endpoints.createSurvey.initiate({ communityId, ...createValues })
      ).unwrap();

      const { emails, questions } = payload;
      await dispatch(surveyApi.endpoints.createSurveyUsers.initiate({ id, emails })).unwrap();
      await dispatch(surveyApi.endpoints.createSurveyQuestions.initiate({ id, questions })).unwrap();

      await dispatch(surveyApi.endpoints.publishSurvey.initiate({ id })).unwrap();
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateSurvey = createAsyncThunk<void, SerializedSurveyFormData>(
  '[SurveyWizard] update initialized',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const surveyData = selectCurrentSurvey(state);
    const isQuestionsReadOnly = selectIsCurrentQuestionsReadOnly(state);
    assert(surveyData);

    const { id } = surveyData;

    const updateFields: Array<keyof AsyncSurvey> = [
      'name',
      'dueBy',
      'reminderIntervalAmount',
      'reminderIntervalTimeUnit',
      'welcomeMessage',
    ];

    const currentValues = pick(surveyData, updateFields);
    const updatedValues = pick(payload, updateFields);

    try {
      await dispatch(surveyApi.endpoints.updateSurvey.initiate({ ...currentValues, ...updatedValues, id })).unwrap();

      const { emails, questions } = payload;
      if (emails.length) {
        await dispatch(surveyApi.endpoints.createSurveyUsers.initiate({ id, emails })).unwrap();
      }

      if (!isQuestionsReadOnly) {
        await dispatch(surveyApi.endpoints.createSurveyQuestions.initiate({ id, questions })).unwrap();
      }
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const createReport = createAsyncThunk<void, void>(
  '[SurveyWizard] create report button clicked',
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const survey = selectCurrentSurvey(state);
    assert(survey);

    const { id, name, invitedUsers } = survey;

    dispatch(
      push(generateDynamicPath(AppRoutes.InsightsReportsWizardSurveys), {
        reportSource: {
          guestCount: invitedUsers,
          id,
          name,
        } as ReportSource,
      })
    );
  }
);

export const surveysWizardActions = {
  reset: createAction('[Survey Wizard] reset'),
  navigationBar: {
    exitButton: {
      clicked: createAction('[Survey Wizard NavigationBar] exit button clicked'),
    },
    deleteButton: {
      clicked: createAsyncThunk<void, void>('[SurveyWizard] delete survey button clicked', async (_, { dispatch }) => {
        dispatch(
          addAppAlertDialog({
            title: 'Delete Survey',
            description: 'This Survey and its data will be removed. Are you sure you want to proceed?',
            showCancelButton: true,
            cancelLabel: 'No, cancel',
            confirmLabel: 'Yes, delete',
            severity: 'danger',
            actionToDispatchOnConfirm: surveysWizardActions.navigationBar.deleteButton.confirmed(),
          })
        );
      }),
      confirmed: createAction('[Surveys Wizard] delete button confirmed'),
    },
    previewButton: {
      clicked: createAction('[Survey Wizard NavigationBar] preview button clicked'),
    },
    createReportButton: {
      clicked: createReport,
    },
  },
  actionButton: {
    createButton: {
      clicked: createAction<{ name: string | undefined }>('[Survey Wizard NavigationBar] create button clicked'),
    },
    cancelButton: {
      clicked: createAction('[Survey Wizard ActionButton] cancel button clicked'),
    },
    backButton: {
      clicked: createAction('[Survey Wizard ActionButton] back button clicked'),
    },
    nextButton: {
      clicked: createAction('[Survey Wizard ActionButton] next button clicked'),
    },
  },
  surveyPreview: {
    closeButton: {
      clicked: createAction('[Survey Wizard NavigationBar] preview close button clicked'),
    },
    submitButton: {
      clicked: createAsyncThunk<void, void>(
        '[Survey Wizard SurveyPreview] submit button clicked',
        (_, { dispatch }) => {
          dispatch(addAppSuccessMessage(`Survey successfully submitted by the end user`));
        }
      ),
    },
  },
  progressTracker: {
    stepCompleted: createAction<SetupSurveyProgressTrackerStep>('[Survey Wizard Progress Tracker] step completed'),
    stepClicked: createAction<SetupSurveyProgressTrackerStep>('[Survey Wizard Progress Tracker] step clicked'),
    setAllStepsCompleted: createAction('[Survey Wizard Progress Tracker] set all steps completed'),
  },
  untitledSurveyDetected: createAction('[Survey Wizard] untitled survey detected'),
  form: {
    submitted: createAction<SerializedSurveyFormData>('[Survey Wizard Form] form submitted'),
  },
};

import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { MultipleSelectFilterOption, MultipleSelectFilter as MultipleSelectFilterRaw } from 'common/components/Filters';

type Props = {
  label: string;
  name: string;
  options: MultipleSelectFilterOption[];
  searchLabel?: string;
  fullWidth?: boolean;
  'data-testid'?: string;
};

export const MultipleSelectFilter: FC<Props> = ({ name, ...props }) => {
  const { field } = useController<{ [key: string]: MultipleSelectFilterOption['value'][] | undefined }>({ name });
  const { value = [], onChange } = field;

  const handleChange = (newValue: (string | number)[]) => {
    onChange({ target: { name, value: newValue } });
  };

  return <MultipleSelectFilterRaw {...props} value={value} onChange={handleChange} />;
};

import { type ImageFile } from 'common/components/_legacy/Form';
import { dataURItoFile } from 'common/utils';
import {
  type EventImage,
  type EventImageApiResponse,
  type EventImageParams,
  type EventSponsor,
  ImageType,
} from 'domain/event';
import { pick } from 'lodash';
import { filter, flow, head, map } from 'lodash/fp';
import type { EventWizardFormValues } from 'pages/EventWizard/validation/schema';

type ConformFormImagesArgs = {
  eventImage: EventWizardFormValues['eventImage'];
  sponsors: EventWizardFormValues['sponsors'];
};

type SelectedImage = {
  imageType: ImageType;
  image: EventWizardFormValues['eventImage'];
  url?: string;
};

type EventImagePayload = Omit<SelectedImage, 'image'> & {
  image: ImageFile;
};

const mapEventImageFromApi = (data: EventImageApiResponse): EventImage => ({
  ...pick(data, ['id']),
  name: data.imageName,
  src: data.imageLink,
});

export const mapEventRelatedImagesToEventImage = flow(
  filter(({ eventRelatedImageType }: EventImageApiResponse) => eventRelatedImageType === ImageType.EventImage),
  head,
  (data) => (data ? mapEventImageFromApi(data) : undefined)
);

const mapSponsorFromApi = (data: EventImageApiResponse): EventSponsor => ({
  ...pick(data, ['id']),
  image: {
    name: data.imageName,
    src: data.imageLink,
  },
  url: data.sponsorUrl,
});

export const mapEventRelatedImagesToSponsors = flow(
  filter(({ eventRelatedImageType }: EventImageApiResponse) => eventRelatedImageType !== ImageType.EventImage),
  map(mapSponsorFromApi)
);

export const conformFormImages = ({ eventImage, sponsors }: ConformFormImagesArgs): Array<Partial<SelectedImage>> => [
  { image: eventImage, imageType: ImageType.EventImage },
  { ...sponsors[0], imageType: ImageType.MainSponsorLogo },
  { ...sponsors[1], imageType: ImageType.OtherSponsorLogo },
];

export const filterOutEmptyAndAlreadyUploadedImages = (selectedImages: Array<Partial<SelectedImage>>) =>
  selectedImages.filter(({ image }) => image?.data && !('id' in image)) as Array<EventImagePayload>;

export const mapImageToApi = ({ imageType, image, url }: EventImagePayload): EventImageParams => ({
  imageType,
  image: dataURItoFile(image.data, image.name),
  ...(url && { sponsorURL: url }),
});

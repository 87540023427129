import { UserRoleName } from 'domain/UserProfile';
import { InferType, mixed, number, object, string } from 'yup';

export const eventHostSchema = object({
  accountId: number().required(),
  name: string(),
  email: string().required(),
  avatarLink: string().nullable(),
  role: string(),
  communityRole: mixed<UserRoleName>().required(),
});

export type EventHostType = InferType<typeof eventHostSchema>;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MicrophoneOffIcon, MicrophoneOnIcon } from 'icons';

import { ActionButton, ActionButtonProps } from '../ActionButton';
import { MicrophoneWarningTooltip } from './MicrophoneWarningTooltip';

type ToggleMicrophoneProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading'> & {
  tooltip?: string;
  isMicrophoneIssueDetected?: boolean;
};

export const ToggleMicrophone: FC<ToggleMicrophoneProps> = ({
  isActive,
  tooltip,
  isMicrophoneIssueDetected = false,
  ...props
}) => {
  const { t } = useTranslation('devices');

  return (
    <MicrophoneWarningTooltip isMicrophoneIssueDetected={isMicrophoneIssueDetected}>
      <ActionButton
        tooltip={tooltip || (isActive ? t('turnOffMicrophone') : t('turnOnMicrophone'))}
        contained={!isActive}
        color={isActive ? undefined : 'error'}
        {...props}
      >
        {isActive ? <MicrophoneOnIcon fontSize="medium" /> : <MicrophoneOffIcon fontSize="medium" />}
      </ActionButton>
    </MicrophoneWarningTooltip>
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import { IconButton, Tooltip } from 'common/components';
import { shadows } from 'common/theme';
import { motion } from 'framer-motion';
import { DoubleArrowLeftIcon } from 'icons';

type Props = {
  isCollapsed: boolean;
  onClick(): void;
};

export const ToggleDrawerButton: FC<Props> = ({ isCollapsed, onClick }) => {
  const { t } = useTranslation('common', { keyPrefix: 'sidebar' });

  return (
    <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.1 }}>
      <Box
        sx={({ spacing }) => ({
          position: 'absolute',
          top: spacing(4),
          right: -2,
          transform: 'translateX(50%)',
          zIndex: 1,
        })}
      >
        <Tooltip
          title={<Typography whiteSpace="nowrap">{t(isCollapsed ? 'expand' : 'collapse')}</Typography>}
          PopperProps={{
            disablePortal: true,
            modifiers: [{ name: 'offset', options: { offset: [0, -16] } }],
          }}
        >
          <Box>
            <IconButton
              onClick={onClick}
              variant="outlined"
              color="light"
              sx={({ palette }) => ({
                boxShadow: shadows.extraLight,
                backgroundColor: palette.common.white,
                transform: isCollapsed ? 'rotate(180deg)' : undefined,
              })}
            >
              <DoubleArrowLeftIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </motion.div>
  );
};

import React, { FC, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Stack } from '@mui/material';
import {
  ImageDropzoneWithPreview as NatterImageDropzoneWithPreview,
  type ImageDropzoneWithPreviewProps as NatterImageDropzoneWithPreviewProps,
} from 'common/components/ImageDropzoneWithPreview';
import { ImageFile } from 'common/components/ImageDropzoneWithPreview/types';
import { Image } from 'common/hooks/useImageCompression/useImageCompression';
import { number, object, string } from 'yup';

import { FormErrors } from '../FormErrors';

type FormValue = Record<string, ImageFile | undefined>;

export const imageSchema = object({
  name: string(),
  data: string(),
  size: number(),
}).default({});

export type ImageDropzoneWithPreviewProps = Pick<
  NatterImageDropzoneWithPreviewProps,
  'onImageCompressionError' | 'disabled'
> & {
  name: string;
  required?: boolean;
  onImageLoaded?(): void;
  onRemoveClick?(): void;
};

export const ImageDropzoneWithPreview: FC<ImageDropzoneWithPreviewProps> = ({
  name,
  onImageLoaded,
  onRemoveClick,
  ...imageDropzoneProps
}) => {
  const { register, setValue, formState, clearErrors } = useFormContext<FormValue>();
  const image = useWatch<FormValue>({ name });

  useEffect(() => {
    register(name);
  }, [name, register]);

  const handleImageSelect = useCallback(
    (file: File) => {
      clearErrors(name);
      setValue(`${name}.name`, file.name);
      setValue(`${name}.size`, file.size);
    },
    [name, setValue, clearErrors]
  );

  const handleImageLoaded = useCallback(
    (file: Image) => {
      setValue(`${name}.status`, 'idle');
      setValue(`${name}.data`, file.data, { shouldValidate: true, shouldDirty: true });
      onImageLoaded?.();
    },
    [name, setValue, onImageLoaded]
  );

  const handleCompressImageProgressChange = useCallback(
    (progress: number) => {
      setValue(`${name}.status`, 'compressing');
      setValue(`${name}.progress`, progress);
    },
    [name, setValue]
  );

  const handleRemoveClick = useCallback(() => {
    setValue(name, undefined, { shouldValidate: true, shouldDirty: true });
    onRemoveClick?.();
  }, [name, setValue, onRemoveClick]);

  return (
    <Stack gap={1}>
      <NatterImageDropzoneWithPreview
        image={image}
        onImageLoaded={handleImageLoaded}
        onImageSelect={handleImageSelect}
        onRemoveClick={handleRemoveClick}
        onProgressChange={handleCompressImageProgressChange}
        {...imageDropzoneProps}
      />
      <FormErrors variant="hint" errors={formState.errors} name={name} />
    </Stack>
  );
};

export interface SocketSubscribeParams {
  publicChannel: string;
  privateChannel?: string;
  actionPrefix: SocketRoomPrefixType;
}

export interface SocketState {
  connectedRooms: string[];
  isConnected: boolean;
  isReconnecting: boolean;
  connectionErrorsCount: number;
}

export const SocketRoomPrefix = {
  Meetings: 'Meetings',
  Availability: 'Availability',
  Questions: 'Questions',
} as const;

export type SocketRoomPrefixType = (typeof SocketRoomPrefix)[keyof typeof SocketRoomPrefix];

export type SocketSendParams = {
  path: string;
  data?: Object;
  plainBody?: boolean;
};

export type SocketMessageAction = `${SocketRoomPrefixType}:${string}`;

export class WebsocketClosedError extends Error {
  constructor(reason: string) {
    super();
    this.name = 'WebsocketClosedError';
    this.message = `Websocket connection closed - ${reason}`;
  }
}

export type SocketConnectivityStatus = 'Stable' | 'Unstable';

import React, { ComponentProps, FC } from 'react';
import { useController } from 'react-hook-form';

import { RadioGroup as RadioGroupInput, RadioGroupOption } from 'common/components/Inputs';

type RadioInputProps = {
  name: string;
  hint?: string;
  label?: string;
  options: RadioGroupOption[];
  autoWidth?: boolean;
  required?: boolean;
} & Pick<ComponentProps<typeof RadioGroupInput>, 'variant'>;

export const RadioGroup: FC<RadioInputProps> = ({ name, required, ...props }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, rules: { required } });

  return <RadioGroupInput name={name} {...props} value={value} onChange={onChange} error={error} />;
};

import React, { FC, ReactNode } from 'react';

import { Box, DrawerProps, IconButton, Stack, Typography } from '@mui/material';
import { CloseIcon } from 'icons';

import { StyledDrawer } from './Drawer.styled';

type Props = {
  isOpen: boolean;
  onClose(): void;
  title?: ReactNode;
  description?: ReactNode;
  noBackdrop?: boolean;
} & DrawerProps;

export const Drawer: FC<Props> = ({ title, description, isOpen, children, noBackdrop, onClose, ...props }) => (
  <StyledDrawer anchor="right" open={isOpen} onClose={onClose} hideBackdrop={noBackdrop} {...props}>
    <Stack direction="row" sx={{ width: 480 }} flex={1} maxHeight="100%">
      <Box px={2} py={3}>
        <IconButton onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack pt={3} gap={3}>
        <Stack pr={3}>
          {!!title && (
            <Typography variant="natter-display-xs" pb={0.5}>
              {title}
            </Typography>
          )}
          {!!description && (
            <Typography variant="natter-text-sm" color="grey.700">
              {description}
            </Typography>
          )}
        </Stack>
        {children}
      </Stack>
    </Stack>
  </StyledDrawer>
);

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MeetingWSChannels } from 'common/constants';
import { generateDynamicPath } from 'common/utils/routing';
import { SocketRoomPrefix } from 'store/features/socket';
import { websocketSubscribe, websocketUnsubscribe } from 'store/features/socket/actions';

import type { MeetingWSParams } from '../types/meeting.types';

export const eventStarted_WS = createAction<MeetingWSParams>('Availability:event_started');
export const eventStopped_WS = createAction<MeetingWSParams>('Availability:event_stopped');

export const subscribeToEvent = createAsyncThunk<void, MeetingWSParams>(
  `${SocketRoomPrefix.Meetings}:subscribeToMeeting`,
  (payload, { dispatch }) => {
    dispatch(
      websocketSubscribe({
        actionPrefix: SocketRoomPrefix.Meetings,
        publicChannel: generateDynamicPath<MeetingWSParams>(MeetingWSChannels.public, payload),
        privateChannel: generateDynamicPath<MeetingWSParams>(MeetingWSChannels.private, payload),
      })
    );
  }
);

export const unsubscribeFromEvent = createAsyncThunk<void, MeetingWSParams>(
  `${SocketRoomPrefix.Meetings}:unsubscribeFromMeeting`,
  (payload, { dispatch }) => {
    dispatch(
      websocketUnsubscribe({
        actionPrefix: SocketRoomPrefix.Meetings,
        publicChannel: generateDynamicPath<MeetingWSParams>(MeetingWSChannels.public, payload),
        privateChannel: generateDynamicPath<MeetingWSParams>(MeetingWSChannels.private, payload),
      })
    );
  }
);

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { green } from 'common/theme/palette';

export const TranscriptionIndicator: FC = () => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation('event', { keyPrefix: 'nattering' });

  return (
    <Stack direction="row" alignItems="center" gap={1} color={isMobile ? green[400] : green[600]}>
      <CircularProgress size="20px" color="inherit" disableShrink />
      <Typography variant="natter-text-sm" whiteSpace="nowrap">
        {t('transcriptionActive')}
      </Typography>
    </Stack>
  );
};

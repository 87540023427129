import { Box, styled } from '@mui/material';
import { omitForwardedProps } from 'common/utils';

type Props = {
  centered?: boolean;
  variant?: 'default' | 'square';
  size?: 'default' | 'small';
};

export const LogoContainer = styled(Box, {
  shouldForwardProp: omitForwardedProps<Props>('centered', 'variant', 'size'),
  name: 'LogoContainer',
})<Props>(({ theme: { palette, breakpoints }, centered, variant, size }) => ({
  display: 'flex',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPositionY: 'center',
  transition: 'background-image 0.1s ease-in',
  filter: palette.mode === 'dark' ? 'grayscale(1) invert(1) brightness(1.6)' : undefined,
  backgroundPositionX: centered ? 'center' : undefined,
  [breakpoints.down('sm')]: {
    height: 26,
  },

  '& img': {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
    ...(variant === 'square' && {
      borderRadius: '4px',
      objectFit: 'cover',
    }),
    ...(size === 'small' && {
      height: 24,
      width: 24,
      minWidth: 24,
      minHeight: 24,
    }),
  },

  ...(size === 'small' && {
    height: 24,
    width: 24,
  }),
}));

import { BroadcastDemoteSpeakerApiPayload, BroadcastSpeakerApiPayload } from 'domain/Broadcast';
import { MeetingGuest, MeetingGuestApiPayload } from 'domain/event';

type PayloadType = MeetingGuestApiPayload | BroadcastSpeakerApiPayload | BroadcastDemoteSpeakerApiPayload;

const getName = (data: PayloadType) => {
  if ('name' in data) {
    return data.name;
  }
  if ('userName' in data) {
    return data.userName;
  }
  return '';
};

export const mapToMeetingGuestFromApi = (data: PayloadType): MeetingGuest => ({
  id: Number('id' in data ? data.id : data.accountId),
  name: getName(data),
  avatarLink: 'avatarLink' in data && data.avatarLink !== 'null' ? data.avatarLink : '',
  role: data.role ?? '',
  category: 'category' in data ? data.category : undefined,
  room: 'room' in data ? data.room : undefined,
});

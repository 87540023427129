import { serializeDateField, serializeDateFields } from 'common/utils/time';
import { PaginatedApiPayloadV2 } from 'domain/Common';
import { EventListItemApiResponse } from 'domain/event';
import { mapEventTimeFromApi } from 'event/mappers';
import { orderBy } from 'lodash';
import { flow } from 'lodash/fp';
import { transformPaginatedApiPayload } from 'modules/api/utils';

import { InsightsReportData } from '../insightsReport';
import { AnswerStatistics, isTextAnswerStatistics } from '../insightsReport/types';
import { EventReportData, EventReportSource } from './types';

export const mapAnswerStatisticsList = (data: AnswerStatistics[]) =>
  data.map((answerStatistics) =>
    isTextAnswerStatistics(answerStatistics)
      ? {
          ...answerStatistics,
          themes: orderBy(answerStatistics.themes, 'count', 'desc'),
        }
      : answerStatistics
  );

export const mapEventsReportDataToInsightsReportData = (response: EventReportData): InsightsReportData => ({
  associatedItems: response.associatedEvents.map(serializeDateFields('date')),
  createdAt: serializeDateField(response.createdAt),
  id: response.id,
  name: response.name,
  status: response.status,
  pin: response.pin,
});

export const mapEventsReportListToInsightsReportList = (
  response: PaginatedApiPayloadV2<EventReportData>
): PaginatedApiPayloadV2<InsightsReportData> => ({
  ...response,
  data: response.data.map(mapEventsReportDataToInsightsReportData),
});

const mapToEventReportSource = (event: {
  id: number;
  name: string;
  startDate: string;
  eventDuration: number;
  eventGuestCount: number;
}): EventReportSource => ({
  eventTime: mapEventTimeFromApi({ id: event.id, startTime: event.startDate, durationInMinutes: event.eventDuration }),
  guestCount: event.eventGuestCount,
  id: event.id,
  name: event.name,
});

export const mapEventsListToEventReportSources = flow(
  transformPaginatedApiPayload<EventListItemApiResponse>,
  (payload): PaginatedApiPayloadV2<EventReportSource> => ({
    totalCount: payload.totalCount,
    data: payload.data.map(flow(serializeDateFields('startDate'), mapToEventReportSource)),
  })
);

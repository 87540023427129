import { createSelector } from '@reduxjs/toolkit';
import { MediaTrackState } from 'domain/Broadcast';
import { AgoraProxyMode } from 'domain/event';
import { inRange } from 'lodash';
import { RootState } from 'store';
import { selectCommunitySettings } from 'store/apis/community/selectors';

import { selectCurrentEventPhase } from '../event/selectors';
import { EventPhase } from '../event/types';
import {
  NETWORK_QUALITY_BOTTOM_THRESHOLD,
  NETWORK_QUALITY_TOP_THRESHOLD,
  proxyModeSettingToAgoraProxyMode,
} from './constants';
import { NetworkErrorKey } from './types';

const selectAllAgoraState = (state: RootState) => state.agora;

export const selectIsJoinedToAgora = createSelector(selectAllAgoraState, (state) => state.isJoined);

const selectNetworkQuality = createSelector(selectAllAgoraState, (state) => state.networkQuality);

const selectAudioTrack = createSelector(selectAllAgoraState, (state) => state.audioTrack);
const selectVideoTrack = createSelector(selectAllAgoraState, (state) => state.videoTrack);

export const selectAudioTrackState = createSelector(selectAudioTrack, (audioTrack) => audioTrack.state);
export const selectVideoTrackState = createSelector(selectVideoTrack, (videoTrack) => videoTrack.state);

export const selectAudioTrackError = createSelector(selectAudioTrack, (audioTrack) => audioTrack.error);
export const selectVideoTrackError = createSelector(selectVideoTrack, (videoTrack) => videoTrack.error);

export const selectIsAudioTrackReadyToBeCreated = createSelector(selectAudioTrackError, (error) => error === null);
export const selectIsVideoTrackReadyToBeCreated = createSelector(selectVideoTrackError, (error) => error === null);

export const selectCurrentCameraDeviceId = createSelector(selectVideoTrack, (videoTrack) => videoTrack.currentDeviceId);

export const selectCurrentMicrophoneDeviceId = createSelector(
  selectAudioTrack,
  (audioTrack) => audioTrack.currentDeviceId
);

const selectAgoraRole = createSelector(selectAllAgoraState, (state) => state.role);
export const selectIsAgoraHost = createSelector(selectAgoraRole, (role) => role === 'host');

export const selectIsAudioTrackReadyToPublish = createSelector(
  selectIsJoinedToAgora,
  selectAudioTrack,
  (isJoined, audioTrack) => isJoined && audioTrack.state === MediaTrackState.Enabled
);
export const selectIsVideoTrackReadyToPublish = createSelector(
  selectIsJoinedToAgora,
  selectVideoTrack,
  (isJoined, videoTrack) => isJoined && videoTrack.state === MediaTrackState.Enabled
);

export const selectShouldPublishAudioTrackForBroadcast = createSelector(
  selectIsAudioTrackReadyToPublish,
  selectIsAgoraHost,
  (isReady, isAgoraHost) => isReady && isAgoraHost
);
export const selectShouldPublishVideoTrackForBroadcast = createSelector(
  selectIsVideoTrackReadyToPublish,
  selectIsAgoraHost,
  (isReady, isAgoraHost) => isReady && isAgoraHost
);

export const selectConnectionQualityMessageKey = createSelector(
  selectIsJoinedToAgora,
  selectCurrentEventPhase,
  selectAgoraRole,
  selectNetworkQuality,
  (isJoined, eventPhase, role, networkQuality) => {
    if (!isJoined) {
      return null;
    }

    const sum = networkQuality.reduce(
      (acc, current) => ({
        downlinkNetworkQuality: acc.downlinkNetworkQuality + current.downlinkNetworkQuality,
        uplinkNetworkQuality: acc.uplinkNetworkQuality + current.uplinkNetworkQuality,
      }),
      { downlinkNetworkQuality: 0, uplinkNetworkQuality: 0 } as {
        downlinkNetworkQuality: number;
        uplinkNetworkQuality: number;
      }
    );

    const isDownlinkConnectionUnstable = inRange(
      sum.downlinkNetworkQuality,
      NETWORK_QUALITY_BOTTOM_THRESHOLD,
      NETWORK_QUALITY_TOP_THRESHOLD + 1
    );
    const isUplinkConnectionUnstable = inRange(
      sum.uplinkNetworkQuality,
      NETWORK_QUALITY_BOTTOM_THRESHOLD,
      NETWORK_QUALITY_TOP_THRESHOLD + 1
    );

    const uplinkConnectionMatters =
      (eventPhase === EventPhase.Broadcast && role === 'host') || eventPhase === EventPhase.OneOnOne;

    if (uplinkConnectionMatters && isUplinkConnectionUnstable && isDownlinkConnectionUnstable) {
      return NetworkErrorKey.UnstableConnection;
    }

    if (uplinkConnectionMatters && isUplinkConnectionUnstable) {
      return NetworkErrorKey.UnstableUplinkConnection;
    }

    if (isDownlinkConnectionUnstable) {
      return NetworkErrorKey.UnstableConnection;
    }

    return null;
  }
);

export const selectAgoraSupportedCloudProxyOptions = createSelector(
  selectAllAgoraState,
  (state) => state.supportedCloudProxyOptions
);

/**
 * priority:
 * 1. no cloud proxy
 * 2. UDP
 * 3. TCP
 */
export const selectAgoraProxyMode = createSelector(
  selectAgoraSupportedCloudProxyOptions,
  selectCommunitySettings,
  (supportedCloudProxyOptions, communitySettings) => {
    if (communitySettings?.proxyMode) {
      const communityLevelProxyMode = proxyModeSettingToAgoraProxyMode[communitySettings.proxyMode];
      if (communityLevelProxyMode !== undefined) {
        return communityLevelProxyMode;
      }
    }

    if (!supportedCloudProxyOptions.length) {
      return null;
    }

    if (supportedCloudProxyOptions.includes(AgoraProxyMode.NoProxy)) return AgoraProxyMode.NoProxy;
    if (supportedCloudProxyOptions.includes(AgoraProxyMode.ForceUdp)) return AgoraProxyMode.ForceUdp;
    if (supportedCloudProxyOptions.includes(AgoraProxyMode.ForceTcp)) return AgoraProxyMode.ForceTcp;

    return null;
  }
);

const selectAgoraConnectionTestState = createSelector(selectAllAgoraState, (state) => state.connectionTestState);

export const selectIsAgoraConnectionTestTimedOut = createSelector(
  selectAgoraConnectionTestState,
  (connectionTestState) => connectionTestState === 'timed out'
);

export const selectIsAgoraConnectionTestReady = createSelector(
  selectAgoraProxyMode,
  selectIsAgoraConnectionTestTimedOut,
  (agoraProxyMode, timedOut) => agoraProxyMode !== null || timedOut
);

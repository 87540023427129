import type { Effect } from 'store';
import { communityApi } from 'store/apis/community';
import { setActiveCommunityId } from 'store/features/community';
import { setShowMemberSetupDialog } from 'store/features/user';

export const registerToCommunitySuccessEffect: Effect = ({ payload }, { dispatch }) => {
  const { communityId } = payload as { communityId: number };
  if (!communityId) return;

  dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
  dispatch(setActiveCommunityId(communityId));
  dispatch(setShowMemberSetupDialog(true));
};
